import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableHeader from '../../../PDF/PDFTable/TableHeader.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import List from '../../../PDF/List.component';
import ListItem from '../../../PDF/ListItem.component';
import Paragraph from '../../../PDF/Paragraph.component';
import LetterHead from '../../../PDF/Letterhead.component';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';

export const PoorPerformanceSummaryOfDismissalLetterPDF: React.FC<DocumentProps> = props => {
  const safeValue = (field: any, fallback: string = '') => field?.value ?? fallback;

  const safeArray = (field: any): string[] => {
    return Array.isArray(field?.value) ? field.value.filter((item: any) => item != null) : [];
  };

  return (
      <Document>
        <Page style={styles.body} size="A4" wrap>
          {!!props.logo && (
              <LetterHead
                  imageUrl={props.logo?.imageUrl ?? ''}
                  logoPosition={props.logo?.position ?? 'LEFT'}
              />
          )}
          <Text style={styles.title}>Confidential</Text>
          <Table>
            <TableHeader title="attention" />
            <TableRow>
              <TableCell>Name of Employee: </TableCell>
              <TableCell>{safeValue(props.content?.employeeFullName, 'N/A')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Employee Number: </TableCell>
              <TableCell>{safeValue(props.content?.employeeNumber, 'N/A')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Department/Business Unit</TableCell>
              <TableCell>{safeValue(props.content?.employeeDepartment, 'N/A')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Address</TableCell>
              <TableCell>{safeValue(props.content?.employeeAddress, 'N/A')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>{safeValue(props.content?.date, 'N/A')}</TableCell>
            </TableRow>
          </Table>
          <Paragraph>Dear {safeValue(props.content?.employeeFirstName, 'Employee')},</Paragraph>
          <Text style={{ ...styles.subject }}>
            CONFIRMATION OF SUMMARY DISMISSAL DUE TO INCAPACITY POOR PERFORMANCE
          </Text>
          <Paragraph>
            <Text>
              An Incapacity Poor Performance Hearing held on{' '}
              {safeValue(props.content?.initial_hearing_hearingDate, 'N/A')} refers.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              You were found guilty, alternatively pleaded guilty, on the following incidents of sub-standard performance
              and/or shortfalls:
            </Text>
          </Paragraph>
          <Table>
            {safeArray(props.content?.performanceShortfalls_guilty).map(
                (value: string, index: number) => (
                    <TableRow key={index}>
                      <TableCell width={'100%'}>{value || 'N/A'}</TableCell>
                    </TableRow>
                )
            )}
          </Table>
          <Paragraph>
            <Text>
              Dismissal was found to be the applicable sanction in the circumstances by the chairperson of the hearing
              having considered aggravating and mitigating factors, as applicable. Please find attached a copy of the
              chairperson’s ruling.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              This serves as confirmation that your services are terminated summarily (i.e. with immediate effect).
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>In light of the above we confirm the following:</Text>
          </Paragraph>
          <List>
            <ListItem>
              Your last day of employment with the company will be{' '}
              {safeValue(props.content?.dismissalDate, 'N/A')} (“termination date”) and no further services will be
              rendered with immediate effect.
            </ListItem>
            <ListItem>
              Final remuneration is payable up to {safeValue(props.content?.finalRemunerationDate, 'N/A')};
            </ListItem>
            <ListItem>Any accrued leave as at the termination date will be paid;</ListItem>
            <ListItem>
              Membership of all employer schemes/funds, as applicable, will also terminate on this date as will the
              employer’s obligations in this regard;
            </ListItem>
            <ListItem>A Certificate of Service will be issued to you;</ListItem>
            <ListItem>
              Appropriate UIF documentation will be completed and issued, as required;
            </ListItem>
            <ListItem>
              You are to return all company property on receipt hereof, should you still have any such in your possession;
            </ListItem>
            <ListItem>Confidentiality undertakings continue to apply.</ListItem>
          </List>
          <Paragraph>
            <Text>{safeValue(props.content?.other)}</Text>
          </Paragraph>
          <Paragraph>
            <Text>
              Information on appeal procedures, if any are stipulated in the employer’s disciplinary policy and
              procedures, will be provided on receipt hereof.
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>
              You have the right to refer the matter to the Commission for Conciliation, Mediation & Arbitration or the
              appropriate Bargaining Council Dispute Forum, as appropriate, should you so wish within 30 days of the
              termination date in which case all documents must be served at the following address:
            </Text>
          </Paragraph>
          <Paragraph>
            <Text>{safeValue(props.content?.contactName, "No Contact Name")}</Text>
            <Text>{'\n'}</Text>
            <Text>{safeValue(props.content?.contactAddress, "No Address")}</Text>
            <Text>{'\n'}</Text>
            <Text>{safeValue(props.content?.contactEmail, "No Email")}</Text>
          </Paragraph>
          <Paragraph>
            <Text>Please liaise with {safeValue(props.content?.authorName, 'the author')} in case of queries.</Text>
          </Paragraph>
          <Paragraph>
            <Text>
              This letter is provided in duplicate, alternatively, was sent to you via e-mail. Please sign and return a
              copy to writer hereof in acknowledgement of receipt hereof, alternatively, acknowledge by way of return
              e-mail.
            </Text>
          </Paragraph>
          <Paragraph>Yours sincerely,</Paragraph>
          <Text style={{ ...styles.text, ...styles.fonts }}>
            {safeValue(props.content?.authorName, 'N/A')}
          </Text>
          <Text style={{ ...styles.text, ...styles.fonts }}>
            {safeValue(props.content?.authorJobTitle, 'N/A')}
          </Text>
        </Page>
        {ConfirmationOfReceiptPage(props)}
      </Document>
  );
};
