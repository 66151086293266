import React, { ReactNode, useContext, useMemo, useState } from 'react';
import './StaticIndicator.style.scss';
import { AlertCircle, AuctionIcon, ClockIcon, GroupIcon, SettlementIcon, ToDoListIcon } from '../icon/Icon.component';
import { UserContext, UserContextProps } from '../../App';
import { useErrorHandler } from '../../utils/notification-utils';
import { get } from '../../utils/api-utils';
import CustomTooltip from '../../components/CustomTooltip/CustomTooltip.component';
import { Link } from 'react-router-dom';
import { getFlowableCasesForOrganisationCaseTable } from '../tables/case-table-utils';
import { getEmployee } from '../../graphql/queries';
import { get as getGQ } from '../../utils/graphql-utils';

interface StaticIndicatorValues {
  closedCases: number;
  closedCasesPercentage: number;
  openCases: number;
  openCasesPercentage: number;
  casesWithActiveSuspensions: number;
  casesWithActiveSuspensionsPercentage: number;
  openMisconductCases: number;
  openMisconductCasesPercentage: number;
  openPoorPerformanceCases: number;
  openPoorPerformanceCasesPercentage: number;
  casesWithActiveWarnings: number;
  casesWithActiveWarningsPercentage: number;
  casesWithHearingsConducted: number;
  casesWithHearingsConductedPercentage: number;
  casesWithIncapacityHearingsConducted: number;
  casesWithIncapacityHearingsConductedPercentage: number;
  casesWithDiscussionsConducted: number;
  casesWithDiscussionsConductedPercentage: number;
  casesReferredToDisputePhase: number;
  casesReferredToDisputePhasePercentage: number;
  casesWithPrivateSettlement: number;
  casesWithPrivateSettlementPercentage: number;
  casesSettledAtConciliation: number;
  casesSettledAtConciliationPercentage: number;
  casesLostAtArbitrationOrAdjudication: number;
  casesLostAtArbitrationOrAdjudicationPercentage: number;
  caseWithActiveInvestigations: number;
  caseWithActiveInvestigationsPercentage: number;
  successfulPIPCount: number;
  successfulPIPCountPercentage: number;
  unsuccessfulPIPCount: number;
  unsuccessfulPIPCountPercentage: number;
  casesWithDemotions: number;
  casesWithDemotionsPercentage: number;
  casesWithDismissals: number;
  casesWithDismissalsPercentage: number;
  casesWithCounsellingProcesses: number;
  casesWithCounsellingProcessesPercentage: number;
  casesWithActivePIPs: number;
  casesWithActivePIPsPercentage: number;
}

interface StaticIndicatorTabProps {
  color1: string;
  color2: string;
  icon?: ReactNode;
  data: { [key: string]: any };
  total?: number;
  fill?: string;
}

interface StaticIndicatorItemProps {
  title?: string;
  value?: number;
  percentage?: number;
  icon?: ReactNode;
  toolTip: string;
  link?: string;
}

const StaticIndicatorItem: React.FC<StaticIndicatorItemProps> = (props: StaticIndicatorItemProps) => {
  return (
    <span className="static-indicator-info">
      <p>
        <span className="col  number">{props.value}</span>
        <CustomTooltip content={props.toolTip} direction="bottom">
          <span id={props.title} className="col  percentage">
            {props.percentage}%
          </span>
        </CustomTooltip>
      </p>
      {props.link ? (
        <Link to={props.link}>
          <p style={{ textDecorationLine: 'underline' }} className="col title text-uppercase">
            {props.title}
          </p>
        </Link>
      ) : (
        <p className="col title text-uppercase">{props.title}</p>
      )}
    </span>
  );
};

const StaticIndicatorTab: React.FC<StaticIndicatorTabProps> = (props: StaticIndicatorTabProps) => {
  const Icon = props?.icon;
  return (
    <div
      className="grid-item"
      style={{
        background: `linear-gradient(225deg, ${props.color1} 0%, ${props.color2} 100%`,
      }}
    >
      <div className="icon-container">
        <i className="icon">
          {props.icon ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            <Icon className="icon-icon" height="180px" width="182px" fill="white" />
          ) : null}
        </i>
      </div>
      <div style={{ position: 'relative', zIndex: 100 }} className="row">
        {props.data?.map((item: any, index: number) => {
          return (
            <StaticIndicatorItem
              key={item.title}
              title={item.title}
              value={item.value}
              percentage={item.percentage}
              toolTip={item.toolTip}
              link={item.link}
            />
          );
        })}
      </div>
      <hr style={{ height: '1px', borderWidth: 0, color: 'gray', backgroundColor: 'white', opacity: '20%' }} />
      <div className="d-flex align-items-center justify-content-end">
        <h6 className="font-weight-light text-white text-capitalize px-3">Total No: {props.total}</h6>
      </div>
    </div>
  );
};

const StaticIndicatorSubItem: React.FC<StaticIndicatorItemProps> = (props: StaticIndicatorItemProps) => {
  return (
    <span className="static-indicator-info">
      <p>
        <span className="col  number">{props.value}</span>
        <CustomTooltip content={props.toolTip} direction="bottom">
          <span id={props.toolTip} className="col  percentage">
            {props.percentage}%
          </span>
        </CustomTooltip>
      </p>
      <p className="col title text-uppercase">{props.title}</p>
    </span>
  );
};

const StaticIndicatorSubTab: React.FC<StaticIndicatorTabProps> = (props: StaticIndicatorTabProps) => {
  const Icon = props.icon;
  return (
    <div className="sub-grid-item">
      <div className="icon-container">
        <i className="icon">
          {props.icon ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            <Icon className="icon-icon" height="40px" width="40px" fill="#BD7AE3" />
          ) : null}
        </i>
      </div>
      <div className="row">
        {props.data?.map((item: any, index: number) => {
          return (
            <StaticIndicatorSubItem
              toolTip={item.toolTip}
              key={index}
              title={item.title}
              value={item.value}
              percentage={item.percentage}
            />
          );
        })}
      </div>
    </div>
  );
};

interface StaticIndicatorState {
  data: [];
  values: StaticIndicatorValues | null;
}

const StaticIndicator: React.FC<any> = props => {
  const MAX_RETRIES = 3;
  const RETRY_DELAY_MS = 2000;
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  const [state, setState] = useState<StaticIndicatorState>({
    data: [],
    values: null,
  });

  useMemo(async () => {
    if (currentUser && currentUser.organisationId) {
      const caseData = await getFlowableCasesForOrganisationCaseTable(currentUser.organisationId);
      const cleanedCases = caseData.filter(el => el.caseNumber !== 'None');

      /* after discussion with the client it was decided to revert to current count of active statuses and plan to do counts for new widgets */
      const newValues = {
        openCases: cleanedCases.filter(el => !['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length,
        openCasesPercentage: (
          (cleanedCases.filter(el => !['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length /
            (cleanedCases.length || 1)) *
          100
        ).toFixed(0),
        closedCases: cleanedCases.filter(el => ['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length,
        closedCasesPercentage: (
          (cleanedCases.filter(el => ['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length /
            (cleanedCases.length || 1)) *
          100
        ).toFixed(0),
        openMisconductCases: cleanedCases.filter(
          el => el.caseNumber.includes('M') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
        ).length,
        openMisconductCasesPercentage: (
          (cleanedCases.filter(
            el => el.caseNumber.includes('M') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
          ).length /
            (cleanedCases.length || 1)) *
          100
        ).toFixed(0),
        openPoorPerformanceCases: cleanedCases.filter(
          el => el.caseNumber.includes('P') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
        ).length,
        openPoorPerformanceCasesPercentage: (
          (cleanedCases.filter(
            el => el.caseNumber.includes('P') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
          ).length /
            (cleanedCases.length || 1)) *
          100
        ).toFixed(0),
        casesWithHearingsConducted: cleanedCases.filter(
          el => el.caseStatus.includes('Hearing') && el.caseNumber.includes('M'),
        ).length,
        casesWithHearingsConductedPercentage: (
          (cleanedCases.filter(el => el.caseStatus.includes('Hearing') && el.caseNumber.includes('M')).length /
            (cleanedCases.filter(
              el => el.caseNumber.includes('M') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
            ).length || 1)) *
          100
        ).toFixed(0),
        casesWithDiscussionsConducted: cleanedCases.filter(
          el => el.caseStatus.includes('Discussion') && el.caseNumber.includes('M'),
        ).length,
        casesWithDiscussionsConductedPercentage: (
          (cleanedCases.filter(el => el.caseStatus.includes('Discussion') && el.caseNumber.includes('M')).length /
            (cleanedCases.filter(
              el => el.caseNumber.includes('M') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
            ).length || 1)) *
          100
        ).toFixed(0),
        casesWithActivePIPs: cleanedCases.filter(el => el.caseStatus.includes('PIP') && el.caseNumber.includes('P'))
          .length,
        casesWithActivePIPsPercentage: (
          (cleanedCases.filter(el => el.caseStatus.includes('PIP') && el.caseNumber.includes('P')).length /
            (cleanedCases.filter(
              el => el.caseNumber.includes('P') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
            ).length || 1)) *
          100
        ).toFixed(0),
        casesWithCounsellingProcesses: cleanedCases.filter(el => el.caseStatus.includes('counselling')).length,
        casesWithCounsellingProcessesPercentage: (
          (cleanedCases.filter(el => el.caseStatus.includes('counselling') && el.caseNumber.includes('P')).length /
            (cleanedCases.filter(
              el => el.caseNumber.includes('P') && !['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
            ).length || 1)) *
          100
        ).toFixed(0),
        casesReferredToDisputePhase: cleanedCases.filter(el => el.isDisputeReferred).length,
        casesReferredToDisputePhasePercentage: (
          (cleanedCases.filter(el => el.isDisputeReferred).length /
            (cleanedCases.filter(el => !['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length || 1)) *
          100
        ).toFixed(0),
        suspensionConsidered: cleanedCases.filter(el => el.caseStatus.includes('Suspension Considered')).length,
        casesWithActiveSuspensions: cleanedCases.filter(
          el => el.isSuspensionActive && ['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
        ).length,
        casesWithActiveSuspensionsPercentage: (
          (cleanedCases.filter(
            el => el.isSuspensionActive && ['Case Closed', 'Dispute Referred'].includes(el.caseStatus),
          ).length /
            (cleanedCases.filter(el => !['Case Closed', 'Dispute Referred'].includes(el.caseStatus)).length || 1)) *
          100
        ).toFixed(0),
      };

      get<StaticIndicatorValues>(`/static-indicators/${currentUser.organisationId}`)
        .then(res => {
          // @ts-ignore
          setState(oldState => ({
            ...oldState,
            values: { ...res.data, ...newValues },
          }));
        })
        .catch(error => handleError(error));
    }
  }, []);

  // @ts-ignore
  async function fetchCaseData(retriesLeft = MAX_RETRIES) {
    try {
      let rawCases: any[] = [];
      if (currentUser?.organisationId) {
        rawCases = await getFlowableCasesForOrganisationCaseTable(currentUser?.organisationId);
      }

      const cleanedCases = rawCases?.filter(el => el.caseNumber !== 'None') || [];
      // console.log({ cleanedCases });

      const mergedData = await Promise.all(
          cleanedCases.map(async (caseItem: any) => {
            // GraphQL call to fetch employee record
            let employee: any = {};
            if (caseItem.employeeId) {
              try {
                const response = await getGQ(getEmployee, caseItem.employeeId);
                // @ts-ignore
                employee = response?.data?.getEmployee || {};
              } catch (e) {
                console.warn('No employee record found for:', caseItem.employeeId);
              }
            }

            // Derive caseType from the case number prefix
            let derivedCaseType = '';
            if (caseItem.caseNumber?.includes('M')) derivedCaseType = 'misconduct';
            if (caseItem.caseNumber?.includes('P')) derivedCaseType = 'poorPerformance';

            // Determine employeeName:
            // - First try from GraphQL
            // - If empty, fallback to caseItem.employeeName
            const fallbackName = caseItem.employeeName && caseItem.employeeName !== 'None'
                ? caseItem.employeeName
                : 'N/A';
            const employeeName = (
                (employee?.firstName || '') + ' ' + (employee?.lastName || '')
            ).trim() || fallbackName;

            // Some fields might appear in multiple places. Example:
            // - Sanction can be top-level as caseItem.sanction
            // - Or it might appear in masterProcessVars.overallSanction
            const topLevelSanction = caseItem.sanction || '';
            const fallbackSanction = caseItem.masterProcessVars?.overallSanction || '';
            const finalSanction = topLevelSanction || fallbackSanction;

            // Similarly, dateCaseInitiated can come from caseItem.createdAt or from the process startTime
            const dateInitiatedFallback = caseItem.masterProcessInstance?.startTime || 'N/A';
            const finalDateInitiated = caseItem.createdAt && caseItem.createdAt !== 'None'
                ? caseItem.createdAt
                : dateInitiatedFallback;

            // Build final record for CSV
            return {
              caseType: derivedCaseType,
              employeeId: caseItem.employeeId,
              employeeName: employeeName,
              employeeNumber: employee?.employeeNumber || 'N/A',
              race: employee?.race || 'N/A',
              gender: employee?.gender || 'N/A',
              lineManager: employee?.directManager
                  ? `${employee.directManager.firstName} ${employee.directManager.lastName}`.trim()
                  : 'N/A',
              caseManager: employee?.caseManager || 'N/A',
              department: employee?.department?.name || 'N/A',
              location: employee?.location?.name || 'N/A',
              province: employee?.physicalAddresses?.[0]?.province || 'N/A',
              personWithDisability: employee?.hasDisability ? 'Yes' : 'No',
              caseNumber: caseItem.caseNumber || 'N/A',
              dateCaseInitiated: finalDateInitiated,
              // MISCONDUCT-Focused Fields
              investigationConducted: caseItem?.history?.investigation ? 'Yes' : '',
              employeeSuspended: caseItem?.isSuspensionActive ? 'Yes' : '',
              transgressionCategory: caseItem?.transgressionCategory || '',
              transgression: caseItem?.transgression || '',
              frequency: caseItem?.frequency || '',
              disciplinaryDiscussion: caseItem?.history?.disciplinaryDiscussion ? 'Yes' : '',
              disciplinaryHearing: caseItem?.history?.disciplinaryHearing ? 'Yes' : '',
              sanction: finalSanction,
              sanctionIssuedDate: caseItem?.expiryDate || '',
              sanction2: '',
              sanctionIssuedDate2: '',
              charges: caseItem?.description || '',
              // POOR PERFORMANCE–Focused Fields
              counselling: caseItem?.history?.counselling ? 'Yes' : '',
              pip: caseItem?.history?.pip ? 'Yes' : '',
              extendedPip: caseItem?.history?.extendedPip ? 'Yes' : '',
              successfulPip: caseItem?.history?.successfulPip ? 'Yes' : '',
              incapacityHearing: caseItem?.history?.incapacityHearing ? 'Yes' : '',
              performanceShortfalls: caseItem?.performanceShortfalls && caseItem.performanceShortfalls !== 'null'
                  ? caseItem.performanceShortfalls
                  : '',

              // Common Fields for Both
              appealProcess: caseItem?.history?.appeal ? 'Yes' : '',
              appealOutcome: caseItem?.history?.appealOutcome || '',
              caseClosed: caseItem?.history?.caseClosed ? 'Yes' : '',
              dateCaseClosed: caseItem?.history?.closedAt || '',
              disputeReferred: caseItem?.isDisputeReferred ? 'Yes' : '',
              disputeOutcome: caseItem?.history?.disputeOutcome || ''
            };
          })
      );

      props.setCaseData(mergedData);
      // console.log('Merged CSV Data', mergedData);
    } catch (err) {
      console.error('Error fetching and merging case data:', err);
      if (retriesLeft > 1) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY_MS));
        return fetchCaseData(retriesLeft - 1);
      }
    }
  }

  React.useEffect(() => {
    if (currentUser?.organisationId) {
      fetchCaseData();
    }
  }, [currentUser?.organisationId]);

  return (
    <>
      {props.showMain && (
        <div className="static-indicator-grid">
          <StaticIndicatorTab
            color1={'#50E3C2'}
            color2={'#4AC1FF'}
            data={[
              {
                title: 'Current Cases',
                percentage: state.values?.openCasesPercentage,
                value: state.values?.openCases,
                toolTip: 'Current cases open out of the total cases',
                link: 'cases?filter=open',
              },
              {
                title: 'Closed Cases',
                percentage: state.values?.closedCasesPercentage,
                value: state.values?.closedCases,
                toolTip: 'Current cases closed out of the total cases',
                link: 'cases?filter=closed',
              },
            ]}
            total={state.values ? state.values?.openCases + state.values?.closedCases : 0}
            icon={GroupIcon}
          />
          <StaticIndicatorTab
            color1={'#BD7AE3'}
            color2={'#8461C9'}
            data={[
              {
                title: 'Current Misconduct Cases',
                percentage: state.values?.openMisconductCasesPercentage,
                value: state.values?.openMisconductCases,
                toolTip: 'Current misconduct cases out of the total cases',
                link: 'cases?filter=misconduct',
              },
              {
                title: 'Current Poor Performance Cases',
                percentage: state.values?.openPoorPerformanceCasesPercentage,
                value: state.values?.openPoorPerformanceCases,
                toolTip: 'Current poor performance cases out of the total cases',
                link: 'cases?filter=performance',
              },
            ]}
            total={state.values ? state.values?.openMisconductCases + state.values?.openPoorPerformanceCases : 0}
            icon={AuctionIcon}
          />
          <StaticIndicatorTab
            color1={'#7254F2'}
            color2={'#55F0FC'}
            data={[
              {
                title: 'Current Disciplinary Hearings',
                percentage: state.values?.casesWithHearingsConductedPercentage,
                value: state.values?.casesWithHearingsConducted,
                toolTip: 'Current cases with disciplinary hearings out of the current misconduct cases',
                link: 'cases?filter=hearings',
              },
              {
                title: 'Current Disciplinary Discussions',
                percentage: state.values?.casesWithDiscussionsConductedPercentage,
                value: state.values?.casesWithDiscussionsConducted,
                toolTip: 'Cases with discussions out of the current misconduct cases',
                link: 'cases?filter=discussions',
              },
            ]}
            total={
              state.values ? state.values?.casesWithHearingsConducted + state.values?.casesWithDiscussionsConducted : 0
            }
            icon={AuctionIcon}
          />
          <StaticIndicatorTab
            color1={'#FFB97A'}
            color2={'#FF69A4'}
            data={[
              {
                title: 'Current Pips',
                percentage: state.values?.casesWithActivePIPsPercentage,
                value: state.values?.casesWithActivePIPs,
                toolTip: 'Cases with an active PIP out of the current poor performances cases',
                link: 'cases?filter=activepip',
              },
              {
                title: 'Current counselling Processes',
                percentage: state.values?.casesWithCounsellingProcessesPercentage,
                value: state.values?.casesWithCounsellingProcesses,
                toolTip: 'Current cases with a counselling process out of the current poor performances cases',
                link: 'cases?filter=counselling',
              },
            ]}
            total={state.values ? state.values?.casesWithActivePIPs + state.values?.casesWithCounsellingProcesses : 0}
            icon={ClockIcon}
          />
        </div>
      )}
      {props.showBottomMain && (
        <div className="static-indicator-grid">
          <StaticIndicatorTab
            color1={'#FFB97A'}
            color2={'#FF69A4'}
            data={[
              {
                title: 'Successful PIPs',
                percentage: state.values?.successfulPIPCountPercentage,
                value: state.values?.successfulPIPCount,
                toolTip: 'Cases with successful PIPs out of the total cases with PIPs',
              },
              {
                title: 'Unsuccessful PIPs',
                percentage: state.values?.unsuccessfulPIPCountPercentage,
                value: state.values?.unsuccessfulPIPCount,
                toolTip: 'Cases with unsuccessful PIPs out of the total cases with PIPs',
              },
            ]}
            total={state.values ? state.values?.unsuccessfulPIPCount + state.values?.successfulPIPCount : 0}
            icon={GroupIcon}
          />
          <StaticIndicatorTab
            color1={'#50E3C2'}
            color2={'#4AC1FF'}
            data={[
              {
                title: 'Dismissals',
                percentage: state.values?.casesWithDismissalsPercentage,
                value: state.values?.casesWithDismissals,
                toolTip: 'Dismissal outcomes from total cases',
              },
              {
                title: 'Demotions',
                percentage: state.values?.casesWithDemotionsPercentage,
                value: state.values?.casesWithDemotions,
                toolTip: 'Demotion outcomes from total cases',
              },
            ]}
            total={state.values ? state.values?.casesWithDemotions + state.values?.casesWithDismissals : 0}
            icon={GroupIcon}
          />
        </div>
      )}
      {props.showSub && (
        <div className="static-indicator-grid">
          <div className="sub">
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Current Suspensions',
                  percentage: state.values?.casesWithActiveSuspensionsPercentage,
                  value: state.values?.casesWithActiveSuspensions,
                  toolTip: 'Current cases with suspensions out of total open cases',
                },
              ]}
              icon={AlertCircle}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Current Warnings',
                  percentage: state.values?.casesWithActiveWarningsPercentage,
                  value: state.values?.casesWithActiveWarnings,
                  toolTip: 'Current cases with warnings out of total open cases',
                },
              ]}
              icon={AlertCircle}
            />
            <StaticIndicatorSubTab
              color1={'#FF69A4'}
              color2={'#FFB97A'}
              data={[
                {
                  title: 'Lost at Arbitration / Adjudication',
                  percentage: state.values?.casesLostAtArbitrationOrAdjudicationPercentage,
                  value: state.values?.casesLostAtArbitrationOrAdjudication,
                  toolTip: 'Current cases lost at arbitration or adjudication out of referred to dispute cases',
                },
              ]}
              icon={ToDoListIcon}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Current Investigations',
                  percentage: state.values?.caseWithActiveInvestigationsPercentage,
                  value: state.values?.caseWithActiveInvestigations,
                  toolTip: 'Current cases with active investigations out of total open cases',
                },
              ]}
              icon={ToDoListIcon}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Dispute Phase',
                  percentage: state.values?.casesReferredToDisputePhasePercentage,
                  value: state.values?.casesReferredToDisputePhase,
                  toolTip: 'Cases referred to dispute out of total open cases',
                },
              ]}
              icon={AlertCircle}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Private Settlements',
                  percentage: state.values?.casesWithPrivateSettlementPercentage,
                  value: state.values?.casesWithPrivateSettlement,
                  toolTip: 'Cases referred to private settlements out of total open cases',
                },
              ]}
              icon={SettlementIcon}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Conciliation Settlements',
                  percentage: state.values?.casesSettledAtConciliationPercentage,
                  value: state.values?.casesSettledAtConciliation,
                  toolTip: 'Cases referred to conciliation settlements out of total open cases',
                },
              ]}
              icon={SettlementIcon}
            />
            <StaticIndicatorSubTab
              color1={'#FFB97A'}
              color2={'#FF69A4'}
              data={[
                {
                  title: 'Incapacity Hearings',
                  percentage: state.values?.casesWithIncapacityHearingsConductedPercentage,
                  value: state.values?.casesWithIncapacityHearingsConducted,
                  toolTip: 'Cases with incapacity hearings out of total poor performance cases',
                },
              ]}
              icon={SettlementIcon}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StaticIndicator;
