import React from 'react';
import { Document, Page, Text } from '@react-pdf/renderer';
import { styles } from '../../../PDF/PDFStyles.style';
import Table from '../../../PDF/PDFTable/Table/Table.component';
import TableRow from '../../../PDF/PDFTable/TableRow.component';
import TableCell from '../../../PDF/PDFTable/TableCell.component';
import Paragraph from '../../../PDF/Paragraph.component';
import { DocumentHeader } from '../../common/Header';
import { ConfirmationOfReceiptPage } from '../../common/ConfirmationOfReceiptPage';
import { DocumentProps } from '../../../../configs/document-configs/document-config-types';
import { normalizeName } from '../../../../utils/user-utils';

export const PerformanceImprovementPlanPDF: React.FC<DocumentProps> = (props): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4" wrap>
        <DocumentHeader content={props.content} logo={props.logo} />
        <Paragraph> Dear {normalizeName(props.content.employeeFullName.value as string)}, </Paragraph>
        <Text style={{ ...styles.subject }}>Performance Improvement Plan </Text>
        <Paragraph>
          The employer and you agreed to initiate a Performance Improvement Plan (“PIP”) arising from concerns regarding
          your performance as communicated to you and/or as per your recent performance rating.
        </Paragraph>
        <Paragraph>
          The parties concluded that the following factors contributed to your failure to meet performance standards:
        </Paragraph>
        {Array.isArray(props.content.pipImpactingFactors.value) &&
            !!props.content.pipImpactingFactors.value[0] &&
            props.content.pipImpactingFactors.value.map((item: string, index: number) => (
                <Paragraph key={index}>
                  {item}
                </Paragraph>
            ))}
        <Paragraph>
          The PIP is a collaborative process between you and the employer with the intention of focusing on key
          deliverables to improve your performance.
        </Paragraph>
        <Paragraph>
          This process will take place from {props.content.pipStartDate.value} to {props.content.pipEndDate.value} and
          the parties will review your performance during this period. Progress review meeting dates will be
          communicated to you from time to time with reasonable being given.
        </Paragraph>
        <Paragraph>
          We agreed on deliverables and time frames against which your progress will be measured. We also agreed on your
          responsibilities and what assistance you require of us as employer. Ultimately, you remain responsible to
          achieve the required deliverables. The terms of your PIP are set out below:
        </Paragraph>
      </Page>
      <Page style={styles.body} orientation={'landscape'} size="A4" wrap>
        <Table>
          <TableRow>
            <TableCell width="17%">Performance shortfall(s) </TableCell>
            <TableCell width="18%">Performance/ Competency Goal </TableCell>
            <TableCell width="17%">Quality deliverable/ standard required</TableCell>
            <TableCell width="16%">Deadline/ time frame </TableCell>
            <TableCell width="16%">Actions to be taken by Employee </TableCell>
            <TableCell width="16%">Assistance required from Employer </TableCell>
          </TableRow>
          {Array.isArray(props.content.performanceShortfalls.value) &&
            props.content.performanceShortfalls.value.map((val: string | string[], index: number) => (
              <TableRow key={index}>
                <TableCell width="17%">{val}</TableCell>
                <TableCell width="18%">
                  {Array.isArray(props.content.performanceGoal.value)
                    ? props.content.performanceGoal.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="17%">
                  {Array.isArray(props.content.qualityStandard.value)
                    ? props.content.qualityStandard.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.deadline.value) ? props.content.deadline.value[index] : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.actionsToBeTakenByEmployee.value)
                    ? props.content.actionsToBeTakenByEmployee.value[index]
                    : 'N/A'}
                </TableCell>
                <TableCell width="16%">
                  {Array.isArray(props.content.assistanceRequiredFromEmployer.value)
                    ? props.content.assistanceRequiredFromEmployer.value[index]
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
        </Table>
      </Page>
      <Page style={styles.body} size="A4" wrap>
        <Paragraph>
          <Text>{/*freeText.value*/}</Text>
        </Paragraph>
        <Paragraph>
          Once you have met the required objectives and your performance remain on an acceptable standard as per our
          expectations, the performance improvement process will be officially terminated. Should you, however, not meet
          the required objectives or insufficient progress is shown the employer will progress the performance
          improvement process.
        </Paragraph>
        <Paragraph>
          Ultimately, we could initiate an Incapacity Hearing: Poor Performance where an independent Chairperson will
          review and assess the matter. A way forward will be determined, which may include the termination of your
          services, if necessary.{' '}
        </Paragraph>
        <Paragraph>
          We assure you of our commitment to assist you in improving your performance to an acceptable standard and
          encourage you to be pro-active and focused in your approach. Please discuss any concerns you may have with us
          timeously.
        </Paragraph>
        <Paragraph>
          Please sign a copy of this PIP to show your commitment to the content hereof and return to writer at your
          soonest convenience, alternatively, confirm acceptance by way of return e-mail.
        </Paragraph>
        <Paragraph>Yours sincerely,</Paragraph>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorName.value}</Text>
        <Text style={{ ...styles.text, ...styles.fonts }}>{props.content.authorJobTitle.value}</Text>
      </Page>
      {ConfirmationOfReceiptPage(props)}
    </Document>
  );
};
