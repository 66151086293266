import React, { useState } from 'react';
import TopBarComponent from '../../components/topBar/TopBar.component';
import StaticIndicator from '../../components/staticIndicators/StaticIndicator';
import MyTasks from '../../components/dashboardWidgets/myTasks';
import TeamTasks from '../../components/dashboardWidgets/teamTasks';
import '../../components/dashboardWidgets/widgets.style.scss';
import SingleVarChart from '../AnalyticsScreen.tsx/charts/SingleVarChart';
import { exportToCSV } from '../../utils/export-csv';
import { Spinner } from 'reactstrap';

const Dashboard: React.FC = () => {
    const [caseData, setCaseData] = useState<any[]>();

    return (
        <>
            <TopBarComponent title={ 'Dashboard' } hideSearch color={ 'white' } style={ { height: '60px' } }>
                { ' ' }
                <button onClick={ () => exportToCSV(caseData) } className="btn btn-primary" disabled={ !caseData }>
                    Export to CSV { caseData ? '' : <Spinner color="default"
                                                             style={ { marginLeft: '8px', width: '12px', height: '12px' } }/> }
                </button>
            </TopBarComponent>
            <br/>

            <div className="content" style={ { background: '#F5F5F5' } }>
                <StaticIndicator setCaseData={ setCaseData } showMain/>
                <div className="widgets">
                    <div className="row">
                        <div className="col-4">
                            <MyTasks/>
                        </div>
                        <div className="col-8">
                            <TeamTasks/>
                        </div>
                    </div>
                </div>

                <SingleVarChart/>
                {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/ }
                {/*  <LineGraphThin />*/ }
                {/*  /!*<BarGraph data={quarterData} heading={`Quarterly Cases (${year})`} dataHeading="Active Cases" />*!/*/ }
                {/*</div>*/ }
                {/*<div style={{ display: 'flex', flexDirection: 'row' }}>*/ }
                {/*  <PieChart />*/ }
                {/*  /!*<BarGraph data={quarterData} heading={`Quarterly Cases (${year})`} dataHeading="Active Cases" />*!/*/ }
                {/*</div>*/ }
            </div>
        </>
    );
};

export default Dashboard;
