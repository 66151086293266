interface CaseRecord {
    caseType?: string;
    employeeId: string;
    employeeName: string;
    employeeNumber?: string;
    caseNumber: string;
    lineManager?: string;
    caseManager?: string;
    department?: string;
    location?: string;
    province?: string;
    race?: string;
    gender?: string;
    personWithDisability?: string;
    dateCaseInitiated?: string;
    counselling?: string;
    pip?: string;
    extendedPip?: string;
    successfulPip?: string;
    incapacityHearing?: string;
    performanceShortfalls?: string;
    investigationConducted?: string;
    employeeSuspended?: string;
    transgressionCategory?: string;
    transgression?: string;
    frequency?: string;
    disciplinaryDiscussion?: string;
    sanction?: string;
    sanctionIssuedDate?: string;
    disciplinaryHearing?: string;
    sanction2?: string;
    sanctionIssuedDate2?: string;
    charges?: string;
    appealProcess?: string;
    appealOutcome?: string;
    caseClosed?: string;
    dateCaseClosed?: string;
    disputeReferred?: string;
    disputeOutcome?: string;
    [key: string]: any;
}

const COMBINED_CSV_COLUMNS = [
    { label: 'Case Type', getter: (obj: any) => obj.caseType || '' },
    { label: 'Employee Name', getter: (obj: any) => obj.employeeName || '' },
    { label: 'Employee Number', getter: (obj: any) => obj.employeeNumber || '' },
    { label: 'Case Number', getter: (obj: any) => obj.caseNumber || '' },
    { label: 'Line Manager', getter: (obj: any) => obj.lineManager || '' },
    { label: 'Case Manager', getter: (obj: any) => obj.caseManager || '' },
    { label: 'Department', getter: (obj: any) => obj.department || '' },
    { label: 'Location', getter: (obj: any) => obj.location || '' },
    { label: 'Province', getter: (obj: any) => obj.province || '' },
    { label: 'Race', getter: (obj: any) => obj.race || '' },
    { label: 'Gender', getter: (obj: any) => obj.gender || '' },
    { label: 'Person with a disability', getter: (obj: any) => obj.personWithDisability || '' },
    { label: 'Date case initiated', getter: (obj: any) => obj.dateCaseInitiated || '' },
    { label: 'Counselling (Y/N)', getter: (obj: any) => obj.counselling || '' },
    { label: 'PIP (Y/N)', getter: (obj: any) => obj.pip || '' },
    { label: 'Extended PIP (Y/N)', getter: (obj: any) => obj.extendedPip || '' },
    { label: 'PIP / Extended PIP successful (Y/N)', getter: (obj: any) => obj.successfulPip || '' },
    { label: 'Incapacity hearing (Y/N)', getter: (obj: any) => obj.incapacityHearing || '' },
    { label: 'Performance shortfalls', getter: (obj: any) => obj.performanceShortfalls || '' },
    { label: 'Investigation conducted (Y/N)', getter: (obj: any) => obj.investigationConducted || '' },
    { label: 'Employee Suspended (Y/N)', getter: (obj: any) => obj.employeeSuspended || '' },
    { label: 'Transgression category', getter: (obj: any) => obj.transgressionCategory || '' },
    { label: 'Transgression', getter: (obj: any) => obj.transgression || '' },
    { label: 'Frequency', getter: (obj: any) => obj.frequency || '' },
    { label: 'Disciplinary discussion (Y/N)', getter: (obj: any) => obj.disciplinaryDiscussion || '' },
    { label: 'Sanction / Outcome', getter: (obj: any) => obj.sanction || '' },
    { label: 'Sanction / Outcome issued - Date', getter: (obj: any) => obj.sanctionIssuedDate || '' },
    { label: 'Disciplinary hearing (Y/N)', getter: (obj: any) => obj.disciplinaryHearing || '' },
    { label: 'Sanction / Outcome', getter: (obj: any) => obj.sanction2 || '' },
    { label: 'Sanction / Outcome issued - Date', getter: (obj: any) => obj.sanctionIssuedDate2 || '' },
    { label: 'Charges', getter: (obj: any) => obj.charges || '' },
    { label: 'Appeal process (Y/N)', getter: (obj: any) => obj.appealProcess || '' },
    { label: 'Appeal outcome', getter: (obj: any) => obj.appealOutcome || '' },
    { label: 'Case closed', getter: (obj: any) => obj.caseClosed || '' },
    { label: 'Date Case closed', getter: (obj: any) => obj.dateCaseClosed || '' },
    { label: 'Dispute referred (Y/N)', getter: (obj: any) => obj.disputeReferred || '' },
    { label: 'Dispute outcome', getter: (obj: any) => obj.disputeOutcome || '' },
];

export function exportToCSV(caseData?: CaseRecord[]) {
    if (!caseData) {
        console.warn('No data available to export.');
        return;
    }
    const csvString = convertToCSV(caseData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'caseData.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

function convertToCSV(dataArray: CaseRecord[]) {
    const headerRow = COMBINED_CSV_COLUMNS.map(col => col.label).join(',');
    let csvRows = headerRow + '\n';
    dataArray.forEach(obj => {
        const rowData = COMBINED_CSV_COLUMNS.map(col => {
            const rawValue = col.getter(obj);
            return rawValue.replace(/"/g, '""');
        });
        csvRows += rowData.join(',') + '\n';
    });
    return csvRows;
}
