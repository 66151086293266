// app/(or pages)/AnalyticsScreen.tsx/SingleVarChart.tsx
import React, { useContext, useState } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
// @ts-ignore
import jsPDF from 'jspdf';
// @ts-ignore
import html2canvas from 'html2canvas';
import { UserContext, UserContextProps } from '../../../App';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import {
  getTimePeriodFromYear,
  OptionType,
  TIME_INTERVAL_OPTIONS,
  TIME_PERIOD_OPTIONS,
  YEAR_OPTIONS,
} from './chart-options/chart-options-utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  tableFieldSelectStyles,
  ValueContainer,
} from '../../../components/reactSelect/ReactSelectComponents.component';
import Select, { ValueType } from 'react-select';
import { post } from '../../../utils/api-utils';
import {
  CaseDataSetQuery,
  ChartsQuery,
  DateIntervalType,
  MultiDataSet,
} from './chart-options/case-reports-types';
import ChartDropdownsSingleSelect from './chart-options/ChartDropdownsSingleSelect';
import ChartDropdownsMultiSelects from './chart-options/ChartDropdownsMultiSelects';
import Loader from '../../../components/loader/Loader';
import { toTitleCase } from '../../../utils/string-utils';
import { LineGraph } from './graphs/LineGraph';
import { BarGraph } from './graphs/BarGraph';
import { DATE_FORMAT_DASHES } from '../../../utils/date-formats';
import { json2csv } from 'json-2-csv';

// @ts-ignore
const moment = extendMoment(Moment);

// A structure to store each selected chart item
interface PdfChartItem {
  base64: string;
  label: string;
}

const SingleVarChart: React.FC = () => {
  const [gradients] = useState(true);
  const [differentColours] = useState(false);

  // Chart states
  const [queries, setQueries] = useState<CaseDataSetQuery[]>([]);
  const [queryFilter, setQueryFilter] = useState<CaseDataSetQuery | null>(null);
  const [loadingGraph, setLoadingGraph] = useState<boolean>(false);
  const [currentDataSet, setCurrentDataSet] = useState<MultiDataSet[]>([]);

  // Time-based states
  const [timePeriodType, setTimePeriodType] = useState<OptionType | null>(TIME_PERIOD_OPTIONS[0]);
  const [yearPeriod, setYearPeriod] = useState<OptionType | null>(YEAR_OPTIONS[1]);
  const [intervalsType, setIntervalsType] = useState<OptionType | null>(TIME_INTERVAL_OPTIONS[0]);
  const [appliedIntervalsType, setAppliedIntervalsType] = useState<OptionType | null>(
      TIME_INTERVAL_OPTIONS[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(moment().startOf('year').toDate());
  const [endDate, setEndDate] = useState<Date | null>(moment().toDate());

  // Current user
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;

  // Popup state
  const [showPopup, setShowPopup] = useState(false);

  // The list of selected charts
  const [pdfCharts, setPdfCharts] = useState<PdfChartItem[]>([]);

  const getChartHeading = (): string => {
    const queriesInfo = queries
        .map((q) => {
          const attrName =
              q.attributeName.charAt(0).toUpperCase() +
              q.attributeName.slice(1).toLowerCase();
          return `${attrName} - ${q.label}`;
        })
        .join(', ');

    const timePeriodText =
        timePeriodType?.value === 'year'
            ? yearPeriod?.label
            : `${moment(startDate).format(DATE_FORMAT_DASHES)} - ${moment(endDate).format(
                DATE_FORMAT_DASHES
            )}`;

    const heading = `${queriesInfo} | ${intervalsType?.label || ''} cases ${timePeriodText}`;
    return toTitleCase(heading, ' ');
  };

  const getCurrentTimePeriod = () => {
    if (
        timePeriodType?.value === 'year' &&
        yearPeriod?.value &&
        typeof yearPeriod.value === 'string'
    ) {
      return getTimePeriodFromYear(yearPeriod.value);
    } else {
      return {
        startDate: startDate ? moment(startDate).format(DATE_FORMAT_DASHES) : '2019-01-01',
        endDate: endDate ? moment(endDate).format(DATE_FORMAT_DASHES) : '2099-01-01',
      };
    }
  };

  const getPDFFileName = (): string => {
    const variablesText = queries.map(q => q.label).join('-') || '';
    const timePeriodText =
        timePeriodType?.value === 'year'
            ? yearPeriod?.label
            : `${moment(startDate).format(DATE_FORMAT_DASHES)}-${moment(endDate).format(
                DATE_FORMAT_DASHES
            )}`;
    const intervalsText = intervalsType?.label || '';
    return `${variablesText}-${timePeriodText}-${intervalsText}`
        .replace(/\s+/g, '-')
        .replace(/--+/g, '-')
        .replace(/(^-|-$)/g, '');
  };

  const onClickUpdate = () => {
    const json2csvCallback = (err?: Error, csv?: string) => {
      if (err) {
        console.error('Error converting to CSV:', err);
        return;
      }
      if (!csv) {
        console.warn('No CSV data returned');
        return;
      }
      // If needed, generate a CSV file here
    };

    if (currentUser?.organisationId) {
      const chartsQuery: ChartsQuery = {
        dataSetQueries: queries,
        dateIntervalType: intervalsType?.value as DateIntervalType,
        organisationId: currentUser.organisationId,
        timePeriod: getCurrentTimePeriod(),
      };
      if (queryFilter) {
        chartsQuery.filterDataSetQuery = queryFilter;
      }
      setLoadingGraph(true);

      post<MultiDataSet[]>('/case-reporting', chartsQuery)
          .then(res => {
            if (res.data && res.data.length > 0) {
              // optional CSV logic
              if (false) {
                const cases: any[] = [];
                res.data.forEach(dataset => {
                  dataset.data.forEach(el => {
                    if (el.cases) {
                      el.cases.forEach((object: any) => cases.push(object));
                    }
                  });
                });
                json2csv(cases, json2csvCallback);
              }
            }
            setAppliedIntervalsType(intervalsType);
            setCurrentDataSet(res.data);
            setLoadingGraph(false);
          })
          .catch(() => {
            setLoadingGraph(false);
          });
    }
  };

  // Build a label from heading + current filter
  const buildChartLabel = (): string => {
    const heading = getChartHeading();
    return `${heading}`;
  };

  // Add the current chart + label to pdfCharts
  const addChartToPDFList = () => {
    const input = document.getElementById('chart-to-pdf');
    if (!input) return;

    const label = buildChartLabel();
    html2canvas(input, { scale: 3 }).then((canvas: HTMLCanvasElement) => {
      const imgData = canvas.toDataURL('image/png');
      setPdfCharts(prev => [...prev, { base64: imgData, label }]);
    });
  };

  // Remove a chart from pdfCharts
  const removeChart = (index: number) => {
    setPdfCharts(prev => prev.filter((_, i) => i !== index));
  };

  // Generate a multi-page PDF from pdfCharts
  const buildPDFfromList = () => {
    if (!pdfCharts.length) return;
    const pdf = new jsPDF('p', 'mm', 'a4');

    const marginLeft = 15;
    const marginTop = 20;
    const pageWidth = pdf.internal.pageSize.getWidth() - marginLeft * 2;

    pdfCharts.forEach((chartItem, index) => {
      if (index > 0) {
        pdf.addPage();
      }

      const chartTop = marginTop + 10;
      const img = new Image();
      img.src = chartItem.base64;

      pdf.addImage(
          img,
          'PNG',
          marginLeft,
          chartTop,
          pageWidth,
          0
      );
    });

    pdf.save('multiple-charts.pdf');
  };

  // Render the main chart area
  const renderCharts = () => {
    if (appliedIntervalsType?.value === DateIntervalType.MONTH) {
      return (
          <div style={{ width: '100%', margin: '20px', boxSizing: 'border-box' }}>
            <Card className="shadow mb-5 bg-white" style={{ alignItems: 'center' }}>
              <LineGraph
                  multiDataSets={currentDataSet}
                  heading={getChartHeading()}
                  gradients={gradients}
                  colourStart={differentColours ? 60 : 270}
              />
            </Card>
          </div>
      );
    } else if (
        appliedIntervalsType?.value === DateIntervalType.QUARTER ||
        appliedIntervalsType?.value === DateIntervalType.YEAR
    ) {
      return (
          <BarGraph
              multiDataSets={currentDataSet}
              heading={getChartHeading()}
              gradients={gradients}
              colourStart={differentColours ? 60 : 270}
          />
      );
    } else {
      return <div />;
    }
  };

  // Render the popup listing selected charts
  const renderSelectedChartsPopup = () => {
    if (!showPopup) return null;
    return (
        <div
            onMouseEnter={() => setShowPopup(true)}
            onMouseLeave={() => setShowPopup(false)}
            style={{
              position: 'absolute',
              top: '3.2rem', // adjust or align as needed
              right: 0,
              zIndex: 999,
              width: '280px',
            }}
        >
          <Card className="shadow" style={{ backgroundColor: '#fff' }}>
            <CardBody>
              <h6 style={{ fontWeight: 600, marginBottom: '1rem', color: '#000' }}>
                Selected Charts
              </h6>
              {pdfCharts.length === 0 && <p style={{ color: '#000' }}>No charts added yet.</p>}
              {pdfCharts.map((chartItem, index) => (
                  <Row
                      key={index}
                      style={{
                        marginBottom: '8px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #dedede',
                        paddingBottom: '6px',
                      }}
                  >
                    <Col xs="8">
                  <span style={{ fontSize: '0.9rem', color: '#000' }}>
                    {chartItem.label}
                  </span>
                    </Col>
                    <Col xs="4" style={{ textAlign: 'right' }}>
                      <Button
                          size="sm"
                          color="danger"
                          style={{ fontSize: '0.8rem' }}
                          onClick={() => removeChart(index)}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
              ))}
            </CardBody>
          </Card>
        </div>
    );
  };

  return (
      <div className="px-3 mt-4">
        <Card className="shadow bg-white" style={{ alignItems: 'left' }}>
          <CardBody>
            <Row
                className="d-flex justify-content-between align-items-center py-0 px-3"
                style={{ height: '30px' }}
            >
              <Col xs="auto">
                <h4 className="text-default" style={{ marginTop: '7px' }}>
                  CASES OVER TIME
                </h4>
              </Col>
              <Col xs="auto" style={{ position: 'relative', marginTop: '-12px' }}>
                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    style={{ marginRight: '6px' }}*/}
                {/*    onClick={exportChartToPDF}*/}
                {/*>*/}
                {/*  Export to PDF*/}
                {/*</Button>*/}
                <Button
                    color="secondary"
                    style={{ marginRight: '6px' }}
                    onClick={addChartToPDFList}
                >
                  Add Chart to PDF
                </Button>
                <div
                    style={{ display: 'inline-block' }}
                    onMouseEnter={() => setShowPopup(true)}
                    onMouseLeave={() => setShowPopup(false)}
                >
                  <Button color="success"
                          onClick={buildPDFfromList}>
                    Build Chart PDF
                  </Button>
                  {renderSelectedChartsPopup()}
                </div>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={4}>
                <h5 className="text-default">Choose variables for comparison:</h5>
                <ChartDropdownsMultiSelects
                    heading="Filter"
                    onChange={(values: CaseDataSetQuery[]) => setQueries(values)}
                />
                <Button
                    color="primary"
                    className="w-100"
                    onClick={onClickUpdate}
                    disabled={!queries.length}
                >
                  UPDATE CHART
                </Button>
              </Col>
              <Col md={4}>
                <h5 className="text-default">Choose time period:</h5>
                <Select
                    placeholder="Select Time Period"
                    cacheOptions
                    options={TIME_PERIOD_OPTIONS}
                    closeMenuOnSelect
                    styles={tableFieldSelectStyles}
                    value={timePeriodType}
                    onChange={(value: ValueType<any>) => setTimePeriodType(value)}
                    components={{ ValueContainer }}
                />
                {timePeriodType?.value === 'custom' && (
                    <div>
                      <label style={{ color: '#ababab', marginRight: 10 }}>
                        Select start date:
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            shouldCloseOnSelect
                            selected={startDate}
                            onChange={(date: Date | null) => setStartDate(date)}
                        />
                      </label>
                      <br />
                      <label style={{ color: '#ababab', marginRight: 10 }}>
                        Select end date:
                        <DatePicker
                            dateFormat="yyyy-MM-dd"
                            shouldCloseOnSelect
                            selected={endDate}
                            onChange={(date: Date | null) => setEndDate(date)}
                        />
                      </label>
                    </div>
                )}
                {timePeriodType?.value === 'year' && (
                    <div>
                      <Select
                          placeholder="Select Year"
                          cacheOptions
                          value={yearPeriod}
                          options={YEAR_OPTIONS}
                          closeMenuOnSelect
                          styles={tableFieldSelectStyles}
                          onChange={(value: ValueType<any>) => setYearPeriod(value || null)}
                          components={{ ValueContainer }}
                      />
                    </div>
                )}
                <Select
                    placeholder="Choose Time Intervals"
                    cacheOptions
                    options={TIME_INTERVAL_OPTIONS}
                    closeMenuOnSelect
                    styles={tableFieldSelectStyles}
                    value={intervalsType}
                    onChange={(value: ValueType<any>) => setIntervalsType(value)}
                    components={{ ValueContainer }}
                />
              </Col>
              <Col md={4}>
                <h5 className="text-default">Choose filter:</h5>
                <ChartDropdownsSingleSelect
                    heading="Filter"
                    onChange={filter => setQueryFilter(filter)}
                />
              </Col>
            </Row>
            {loadingGraph && (
                <Row className="d-flex flex-row justify-content-center">
                  <Loader />
                </Row>
            )}
            <div id="chart-to-pdf" style={{ display: 'flex', flexDirection: 'row' }}>
              {renderCharts()}
            </div>
          </CardBody>
        </Card>
      </div>
  );
};

export default SingleVarChart;
