import React, {useEffect, useState, useRef, useContext, useCallback} from 'react';
import moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import Loader from '../loader/Loader';
import { UserContext, UserContextProps } from '../../App';
import { useErrorHandler } from '../../utils/notification-utils';
import { getMyTasks, TaskStatus, WidgetTask } from './task-widget-utils';
import { Link } from 'react-router-dom';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import Input from 'reactstrap/lib/Input';
import { SearchIcon } from '../icon/Icon.component';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import { v4 as uuidv4 } from 'uuid';

let ps: PerfectScrollbar;

const getStatus = (status: TaskStatus): JSX.Element => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
      return (
        <div className="inProgress">
          <span className="dot" />
          In Progress
        </div>
      );
    case TaskStatus.DUE_SOON:
      return (
        <div className="dueSoon">
          <span className="dot" />
          Due Soon
        </div>
      );
    case TaskStatus.OVERDUE:
      return (
        <div className="overdue">
          <span className="dot" />
          Overdue
        </div>
      );
    default:
      return <div />;
  }
};
const getStatusText = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
      return 'in progress';
    case TaskStatus.DUE_SOON:
      return 'due soon';
    case TaskStatus.OVERDUE:
      return 'over due';
    default:
      return 'none';
  }
};

interface MyTasksState {
  tasks?: WidgetTask[];
  loading: boolean;
}

const MyTasks: React.FC = () => {
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const handleError = useErrorHandler();
  // demo data
  const [state, setState] = useState<MyTasksState>({ tasks: [], loading: true });
  const [search, setSearch] = useState<string>('');

  const tasksRef = useRef(null);

  const componentWillUnMount = (): void => {
    ps.destroy();
  };

  const componentDidMount = useCallback((): (() => void) => {
    ps = new PerfectScrollbar(tasksRef.current!);

    return componentWillUnMount;
  }, []);

  useEffect(() => {
    if (currentUser) {
      setState(oldState => ({ ...oldState, loading: true }));
      getMyTasks(currentUser.id)
        .then(res => {
          setState(oldState => ({ ...oldState, tasks: res, loading: false }));
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, loading: false }));
        });
    }
  }, [currentUser, handleError]);

  useEffect(() => {
    componentDidMount();
  }, [componentDidMount]);

  const filteredCases =
    search.length > 2
      ? state.tasks?.filter(task => {
          return (
            getStatusText(task.status).includes(search.toLocaleLowerCase()) ||
            task.caseNumber.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            task?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          );
        })
      : state.tasks;
  return (
    <div className="card card-tasks widget myTasks">
      <div className="card-header">
        <div className="card_header_container">
          <h2 style={{ border: 0 }}>MY TASKS</h2>
          <div className="w-75">
            <InputGroup className="no-border mt-sm-0 mb-0 shadow-sm rounded">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="bg-white">
                  <SearchIcon />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                autoComplete="off"
                className="bg-white h-auto"
                name="search"
                placeholder={'Search Tasks'}
                type="text"
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </div>
      </div>

      <div className="card-body" ref={tasksRef}>
        {state.loading ? (
          <Loader />
        ) : (
          filteredCases?.map((task: WidgetTask) => {
            return (
              <div className="task" key={uuidv4()}>
                <div className="status text-uppercase">{getStatus(task.status)}</div>
                <div className="timeStamp text-uppercase">{moment(task.startDate).fromNow()}</div>
                <div className="description text-uppercase">
                  <Link to={'/case/' + task.masterProcessInstanceId}>{task.name}</Link>
                </div>
                <div className="assignee text-uppercase">
                  <Link to={'/case/' + task.masterProcessInstanceId}>{task.caseNumber}</Link>
                </div>
                <div className="assignee text-uppercase">{task.employeeName}</div>
              </div>
            );
          })
        )}
        {filteredCases && filteredCases.length <= 0 && <div className="description">No tasks found</div>}
      </div>
    </div>
  );
};

export default MyTasks;
