import {
  getTasksByAssignee,
  queryHistoricMasterProcessInstancesByEmployeeId,
  queryHistoricMasterProcessInstancesByEmployeeIdAndCaseType,
  queryHistoricMasterProcessInstancesByOrganisationId,
  queryHistoricProcessInstancesByMasterProcessInstanceId,
} from '../../utils/flowable/flowable-utils';
// import { FlowableHistoricProcessInstance } from '../../utils/flowable/flowable-types';
import { FlowableHistoricProcessInstance, FlowableVariable } from '../../utils/flowable/flowable-types';
import { CaseTableData } from '../../utils/case-utils';
import { CaseIncident, CasePerformanceShortfall } from '../../screens/WorkflowContainer/workflow-utils';
import moment, { Moment } from 'moment';
import { toTitleCase } from '../../utils/string-utils';
import { list } from '../../utils/graphql-utils';
import { listPastCases } from '../../graphql/queries';
import { CaseType } from '../../API';
import { PastCase } from '../../models';
import { getEmployeeNameOnly } from '../../utils/employee-utils';
import { notEmpty } from '../../utils/typescript-utils';

export const getFlowableCasesForEmployeeCaseTable = async (
  employeeId: string,
  caseType?: string,
): Promise<CaseTableData[]> => {
  const masterProcessInstances = caseType
    ? await queryHistoricMasterProcessInstancesByEmployeeIdAndCaseType(employeeId, caseType)
    : await queryHistoricMasterProcessInstancesByEmployeeId(employeeId);
  const processInstancePromises = masterProcessInstances.map(m =>
    queryHistoricProcessInstancesByMasterProcessInstanceId(m.id),
  );
  const processInstanceArrays = await Promise.all(processInstancePromises);
  return processInstanceArrays.map(a => getTableData(a));
};

export const getFlowableCasesForOrganisationCaseTable = async (organisationId: string): Promise<CaseTableData[]> => {
  const masterProcessInstances = await queryHistoricMasterProcessInstancesByOrganisationId(organisationId);
  const processInstancePromises = masterProcessInstances.map(m =>
    queryHistoricProcessInstancesByMasterProcessInstanceId(m.id),
  );
  const processInstanceArrays = await Promise.all(processInstancePromises);

  return processInstanceArrays.map(a => getTableData(a));
};

export const getFlowableCasesForExternalUsers = async (userId: string): Promise<CaseTableData[]> => {
  const tasks = await getTasksByAssignee(userId);
  const promises = tasks
    .map(t => t.variables.find(v => v.name === 'masterProcessInstanceId')?.value)
    .filter(id => typeof id === 'string')
    .map(id => queryHistoricProcessInstancesByMasterProcessInstanceId(id as string));
  const processInstanceArrays = await Promise.all(promises);
  const employeeIds: string[] = [];
  tasks.forEach(t => {
    const employeeIdVar = t.variables.find(v => v.name === 'employeeId');
    if (employeeIdVar?.value && typeof employeeIdVar.value === 'string' && !employeeIds.includes(employeeIdVar.value)) {
      employeeIds.push(employeeIdVar.value);
    }
  });
  const employeeNames = await Promise.all(employeeIds.map(id => getEmployeeNameOnly(id)));
  const employeeNamesFlat = employeeNames.flat();
  return processInstanceArrays.map(array => {
    const employeeIdVar = array[0].variables.find(v => v.name === 'employeeId');
    let employeeName = 'None';
    if (employeeIdVar?.value && typeof employeeIdVar.value === 'string') {
      const employee = employeeNamesFlat.find(e => e.id === employeeIdVar.value);
      if (employee) {
        employeeName = toTitleCase(employee.firstName + ' ' + employee.lastName, ' ');
      }
    }
    return getTableData(array, employeeName);
  });
};

const getVarFromProcessInstances = (
  variableName: string,
  processInstances: FlowableHistoricProcessInstance[],
): string | null => {
  for (const p of processInstances) {
    const variable = p.variables.find(v => v.name === variableName);
    if (variable && typeof variable.value === 'string') {
      return variable.value;
    }
  }
  return null;
};

const getVars = (
  varNames: string[],
  processInstances: FlowableHistoricProcessInstance[],
): Record<string, string | null> => {
  const sortedPIs = processInstances.sort((a, b) => moment(b.startTime).unix() - moment(a.startTime).unix());
  const vars: Record<string, string | null> = {};
  for (const varName of varNames) {
    vars[varName] = getVarFromProcessInstances(varName, sortedPIs);
  }
  return vars;
};

const checkDisputeReferred = (processInstances: FlowableHistoricProcessInstance[]): boolean => {
  const ccmaFlow = processInstances.find(process => process.processDefinitionName.includes('ccma'));

  if (ccmaFlow) {
    const disputeReferredToCCMA = ccmaFlow.variables.find(v => v.name === 'disputeReferredToCCMA');
    if (disputeReferredToCCMA) {
      // @ts-ignore
      return disputeReferredToCCMA.value;
    }
  }

  return false;
};

const getReturnToWorkDateFromCase = (flowableCase: FlowableHistoricProcessInstance): Moment | null => {
  let date: Moment | null = null;
  const lastReturnToWorkDateVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'lastReturnToWorkDate',
  );

  //return to work is the lastReturnToWorkDate var set by flowable whenever certain conditions are met.
  const returnToWorkDate =
    lastReturnToWorkDateVar && typeof lastReturnToWorkDateVar.value === 'string' && lastReturnToWorkDateVar.value.length
      ? moment(JSON.parse(lastReturnToWorkDateVar.value))
      : null;

  //return to work from sanction is the form variable submitted when the hearing sanction is suspension without pay.
  const returnToWorkDateFromSanctionVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'returnToWorkDate',
  );

  const returnToWorkDateFromSanction =
    returnToWorkDateFromSanctionVar &&
    typeof returnToWorkDateFromSanctionVar.value === 'string' &&
    returnToWorkDateFromSanctionVar.value.length
      ? moment(returnToWorkDateFromSanctionVar.value, 'DD/MM/YYYY')
      : null;

  if (returnToWorkDate && moment(returnToWorkDate).isBefore(moment())) {
    date = returnToWorkDate;
  }

  if (
    returnToWorkDateFromSanction &&
    moment(returnToWorkDateFromSanction).isBefore(moment()) &&
    moment(returnToWorkDateFromSanction).isAfter(date)
  ) {
    date = returnToWorkDateFromSanction;
  }
  return date;
};

const getSuspensionDateFromCase = (flowableCase: FlowableHistoricProcessInstance): Moment | null => {
  const lastSuspensionDateVar = flowableCase.variables.find(
    (variable: FlowableVariable) => variable.name === 'lastSuspensionDate',
  );
  if (!lastSuspensionDateVar?.value) {
    return null;
  }

  let date: Moment | null = null;

  const lastSuspensionDate =
    lastSuspensionDateVar?.value &&
    typeof lastSuspensionDateVar?.value === 'string' &&
    !!lastSuspensionDateVar.value.length
      ? moment(JSON.parse(lastSuspensionDateVar.value))
      : null;
  if (lastSuspensionDate && moment(lastSuspensionDate).isBefore(moment())) {
    date = lastSuspensionDate;
  }
  return date;
};

const checkActiveSuspension = (processInstance: FlowableHistoricProcessInstance): boolean => {
  let isSuspended = false;
  const suspensionDate = getSuspensionDateFromCase(processInstance);

  if (suspensionDate) {
    const returnToWorkDate = getReturnToWorkDateFromCase(processInstance);
    if (suspensionDate) {
      isSuspended = !(returnToWorkDate && moment(returnToWorkDate).isAfter(suspensionDate));
    }
  }

  return isSuspended;
};

const checkActiveSuspensions = (processInstances: FlowableHistoricProcessInstance[]): boolean => {
  const suspensionFlow = processInstances.find(process => checkActiveSuspension(process));

  return !!suspensionFlow;
};

const getTableData = (processInstances: FlowableHistoricProcessInstance[], employeeName?: string): {
  employeeName: string;
  caseStatus: string;
  description: any;
  employeeId: string;
  history: { [p: string]: string };
  masterProcessVars: Record<string, string>;
  masterProcessInstance: FlowableHistoricProcessInstance;
  caseType: string;
  frequency: any;
  expiryDate: string;
  isCaseClosed: boolean;
  sanction: any;
  processInstances: FlowableHistoricProcessInstance[];
  caseNumber: string;
  isDisputeReferred: boolean;
  isSuspensionActive: boolean;
  showDisputeButton: boolean;
  id: string
} => {
  const masterProcessInstance = processInstances.find(p => {
    const isMasterProcessInstanceVar = p.variables.find(v => v.name === 'isMasterProcessInstance');
    return isMasterProcessInstanceVar?.value === true;
  });
  if (!masterProcessInstance) {
    throw new Error('No masterProcessInstance');
  }

  const masterProcessVars = getStringsFromFlowable(masterProcessInstance.variables);

  const otherVars = getVars(
    [
      'incidents',
      'performanceShortfalls',
      'overallSanction',
      'revisedOverallSanction',
      'disciplinaryDiscussionDecision',
      'warningExpiryDate',
    ],
    processInstances,
  );
  const warningExpiryDate = otherVars.warningExpiryDate;
  const isDisputeReferred = checkDisputeReferred(processInstances);
  const isSuspensionActive = checkActiveSuspensions(processInstances);

  let description, frequencies, sanction;
  const history: { [key: string]: string } = {};

  if (masterProcessVars.caseType === 'MISCONDUCT') {
    if (otherVars.incidents) {
      const incidents: CaseIncident[] = JSON.parse(otherVars.incidents);
      if (Array.isArray(incidents)) {
        const transgressions = incidents.map(i => {
          if (!history[i.transgression]) {
            history[i.transgression] = i.frequency;
          }
          return i.transgression;
        });
        description = transgressions
          .filter(t => !!t)
          .map(t => toTitleCase(t, '_'))
          .join(', ');
        const freqs = incidents.map(i => i.frequency);
        frequencies = freqs
          .filter(f => !!f)
          .map(f => f ?? 'None')
          .join(', ');
      }
    }
  } else if (masterProcessVars.caseType === 'POOR_PERFORMANCE') {
    if (otherVars.performanceShortfalls) {
      const shortfalls: CasePerformanceShortfall[] = JSON.parse(otherVars.performanceShortfalls);
      if (Array.isArray(shortfalls)) {
        description = shortfalls
          .map(s => (s.shortfallDescription ? toTitleCase(s.shortfallDescription, '_') : ''))
          .join(', ');
      }
    }
  }

  if (masterProcessVars.processType === 'DISCUSSION' && otherVars.disciplinaryDiscussionDecision) {
    sanction = otherVars.disciplinaryDiscussionDecision.includes('_')
      ? toTitleCase(otherVars.disciplinaryDiscussionDecision, '_')
      : toTitleCase(otherVars.disciplinaryDiscussionDecision, ' ');
  } else if (masterProcessVars.processType === 'HEARING') {
    if (otherVars.revisedOverallSanction) {
      if (otherVars.revisedOverallSanction === 'NOT_APPLICABLE') {
        sanction = 'Sanction Overturned';
      } else {
        sanction = toTitleCase(otherVars.revisedOverallSanction, '_');
      }
    } else if (otherVars.overallSanction) {
      sanction = toTitleCase(otherVars.overallSanction, '_');
    }
  } else if (masterProcessVars.isCaseClosed) {
    sanction = 'No disciplinary action required.';
  }

  const isCaseClosed = masterProcessInstance.variables.find(v => v.name === 'isCaseClosed')?.value === true;

  return {
    employeeId: masterProcessVars.employeeId ?? 'None',
    employeeName: employeeName ?? 'None',
    caseNumber: masterProcessVars.caseNumber ?? 'None',
    caseStatus: masterProcessVars.caseStatus ?? 'None',
    caseType: masterProcessVars.caseType ?? 'None',
    description: description ?? 'None',
    expiryDate: warningExpiryDate ?? 'None',
    frequency: frequencies ?? 'None',
    id: masterProcessInstance.id,
    sanction: sanction ?? 'None',
    showDisputeButton: isCaseClosed,
    isCaseClosed,
    isDisputeReferred,
    isSuspensionActive,
    history,
    masterProcessInstance,
    masterProcessVars,
    processInstances
  };
};

const getStringsFromFlowable = (flowableVariables: FlowableVariable[]): Record<string, string> => {
  const vars: Record<string, string> = {};
  for (const variable of flowableVariables) {
    if (typeof variable.value === 'string') {
      vars[variable.name] = variable.value;
    }
  }
  return vars;
};

export const getPastCasesFromDynamoDB = async (employeeId: string, caseType?: CaseType): Promise<CaseTableData[]> => {
  const employee = await getEmployeeNameOnly(employeeId);
  const variables = caseType
    ? { filter: { employeeId: { eq: employeeId }, caseType: { eq: caseType } } }
    : { filter: { employeeId: { eq: employeeId } } };
  const res = await list(listPastCases, variables);
  const cases: PastCase[] = (res as any).data.listPastCases.items;
  return cases.map(c => {
    let description;
    if (c.caseType === CaseType.MISCONDUCT) {
      description = c.transgression;
    } else if (c.caseType === CaseType.POOR_PERFORMANCE) {
      description = c.shortfallDescription;
    }

    return {
      id: c.id,
      employeeName: toTitleCase(employee.firstName + ' ' + employee.lastName, ' '),
      employeeId: employeeId,
      caseNumber: c.caseNumber ?? 'None',
      caseStatus: 'Case Closed',
      sanction: c.sanction ?? 'None',
      expiryDate: c.expiryDate ?? 'None',
      description: description ?? 'None',
      caseType: c.caseType ?? 'None',
      frequency: c.frequency ? c.frequency : 'None',
      caseFiles: c.caseFiles?.filter(notEmpty),
    };
  });
};
