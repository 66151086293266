import React, { useEffect, useState, useRef, useContext } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { Table as BootStrapTable } from 'reactstrap';
import Loader from '../loader/Loader';
import { useErrorHandler } from '../../utils/notification-utils';
import { UserContext, UserContextProps } from '../../App';
import { getTeamTasks, TaskStatus, WidgetTask } from './task-widget-utils';
import InputGroup from 'reactstrap/lib/InputGroup';
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon';
import InputGroupText from 'reactstrap/lib/InputGroupText';
import { SearchIcon } from '../icon/Icon.component';
import Input from 'reactstrap/lib/Input';

let ps: PerfectScrollbar;

interface TeamTasksState {
  tasks?: WidgetTask[];
  loading: boolean;
}

const getStatus = (status: TaskStatus): JSX.Element => {
  switch (status) {
    case TaskStatus.IN_PROGRESS:
      return (
        <div className="inProgress">
          <span className="dot" />
          In Progress
        </div>
      );
    case TaskStatus.DUE_SOON:
      return (
        <div className="dueSoon">
          <span className="dot" />
          Due Soon
        </div>
      );
    case TaskStatus.OVERDUE:
      return (
        <div className="overdue">
          <span className="dot" />
          Overdue
        </div>
      );
    default:
      return <div />;
  }
};

const TeamTasks: React.FC = () => {
  const handleError = useErrorHandler();
  const currentUser = useContext<Partial<UserContextProps>>(UserContext).currentUser;
  const [state, setState] = useState<TeamTasksState>({ tasks: [], loading: true });
  const tasksRef = useRef(null);

  const [search, setSearch] = useState<string>('');
  const componentWillUnMount = () => {
    ps.destroy();
  };
  const componentDidMount = (): (() => void) => {
    ps = new PerfectScrollbar(tasksRef.current!);
    return componentWillUnMount;
  };

  useEffect(() => {
    if (currentUser?.organisationId) {
      setState(oldState => ({ ...oldState, loading: true }));
      getTeamTasks(currentUser.id)
        .then((tasks: WidgetTask[]) => {
          setState(oldState => ({ ...oldState, tasks: tasks, loading: false }));
        })
        .catch(error => {
          handleError(error);
          setState(oldState => ({ ...oldState, loading: false }));
        });
    }
  }, []);

  useEffect(() => {
    componentDidMount();
  }, []);

  const getStatusText = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.IN_PROGRESS:
        return 'in progress';
      case TaskStatus.DUE_SOON:
        return 'due soon';
      case TaskStatus.OVERDUE:
        return 'over due';
      default:
        return 'none';
    }
  };

  const filteredCases =
    search.length > 2
      ? state.tasks?.filter(task => {
          return (
            getStatusText(task.status).includes(search.toLocaleLowerCase()) ||
            task.caseNumber.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            task?.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          );
        })
      : state.tasks;

  return (
    <div className="card card-tasks widget teamTasks">
      <div className="card-header">
        <div className="card_header_container">
          <h2 style={{ border: 0 }}>TEAM TASKS</h2>
          <div className="w-75">
            <InputGroup className="no-border mt-sm-0 mb-0 shadow-sm rounded">
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="bg-white">
                  <SearchIcon />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                autoComplete="off"
                className="bg-white h-auto"
                name="search"
                placeholder={'Search Tasks'}
                type="text"
                onChange={e => {
                  setSearch(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </div>
      </div>

      <div className="card-body" ref={tasksRef}>
        {state.loading ? (
          <Loader />
        ) : (
          <BootStrapTable>
            <thead>
              <tr>
                <th className="text-uppercase">Task</th>
                <th className="text-uppercase">Case Number</th>
                <th className="text-uppercase">Assigned</th>
                <th className="text-uppercase">Employee Name</th>
                <th className="text-uppercase">Task Status</th>
                <th className="text-uppercase">Due Date</th>
              </tr>
            </thead>
            <tbody>
              {filteredCases?.map((task: WidgetTask) => {
                return (
                  <tr>
                    <td className="text-uppercase">{task.name}</td>
                    <td className="text-uppercase">{task.caseNumber}</td>
                    <td className="text-uppercase">{task.assigneeUserName}</td>
                    <td className="text-uppercase">{task.employeeName}</td>
                    <td className="status text-uppercase">{getStatus(task.status)}</td>
                    <td className="text-uppercase">{task.dueDate}</td>
                  </tr>
                );
              })}
              {!filteredCases?.length && <tr className="description"><td>No tasks found</td></tr>}
            </tbody>
          </BootStrapTable>
        )}
      </div>
    </div>
  );
};

export default TeamTasks;
