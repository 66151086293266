import React, { ReactElement } from 'react';
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { FieldArray, FieldArrayRenderProps, Form, FormikValues, useFormikContext } from 'formik';
import FormField from '../fields/FormField.component';
import InputRow from '../../components/inputRow/InputRow.component';
import { DocumentJSONContent } from '../../screens/DocumentEditor/DocumentEditor';
import './DocumentFields.styles.scss';

interface DocumentFieldsProps {
    title: string;
    goBack?: () => void;
    saveDocument?: (documentContent: DocumentJSONContent) => Promise<any>;
    isSaving?: boolean;
    isDocumentReadOnly?: boolean;
}

const DocumentFields: React.FC<DocumentFieldsProps> = (props: DocumentFieldsProps): ReactElement => {
    const { title } = props;
    const { values, handleSubmit } = useFormikContext<FormikValues>();

    return (
        <>
            <Card>
                <CardBody className="card-background">
                    <div className="d-flex justify-content-between mr-2 ml-2">
                        <div className="h4 font-weight-bold header-text text-capitalize">{ title }</div>
                        <div className="right-0">
                            <button
                                className="btn btn-simple btn-secondary rounded-0"
                                onClick={ (): void => {
                                    if (props.goBack) props.goBack();
                                } }
                                disabled={ props.isSaving }
                                style={ { height: '40px' } }
                            >
                                CANCEL
                            </button>
                            { !props.isDocumentReadOnly && (
                                <button
                                    className="btn btn-secondary document-editor-button text-center rounded-0"
                                    onClick={ (): void => {
                                        if (props.saveDocument) props.saveDocument(values);
                                    } }
                                    disabled={ props.isSaving || values['pipImpactingFactors']?.value?.[0] === '' }
                                    style={ { height: '40px' } }
                                >
                                    { props.isSaving ?
                                        <i className="spinner-border spinner-border-sm"/> : 'SAVE AND CLOSE' }
                                </button>
                            ) }
                        </div>
                    </div>
                    { !props.isDocumentReadOnly && (
                        <div className="mt-2 mr-3 ml-1 pr-2 pl-1">
                            <Form>
                                <Row>
                                    { Object.keys(values).map((key: string) => {
                                        const type = values[key]?.type;
                                        const label = values[key]?.label;
                                        if (label && type !== 'array' && type !== 'textarea') {
                                            return (
                                                <Col md={ 4 } key={ key }>
                                                    <FormGroup>
                                                        <Label className="text-muted">{ label }</Label>
                                                        <FormField
                                                            type={ type }
                                                            placeholder={ label }
                                                            name={ `${ key }.value` }
                                                            className="text-capitalize"
                                                            style={ { width: 'auto', minWidth: '100%' } }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            );
                                        }
                                        return null;
                                    }) }
                                </Row>
                                { Object.keys(values).map((key: string) => {
                                    const type = values[key]?.type;
                                    const label = values[key]?.label;
                                    const valuesArray = values[key]?.value;
                                    if (label && type === 'array') {
                                        return (
                                            <FieldArray name={ `${ key }.value` } key={ key }>
                                                { (arrayHelpers: FieldArrayRenderProps) => (
                                                    <>
                                                        { valuesArray &&
                                                            valuesArray.length &&
                                                            valuesArray.map((item: string, index: number) => (
                                                                <InputRow
                                                                    key={ index }
                                                                    index={ props.title === 'Suspension Letter' ? 1000000 : index }
                                                                    defaultValues={ '' }
                                                                    addCallback={ arrayHelpers.push }
                                                                    removeCallback={ arrayHelpers.remove }
                                                                    disabled={ props.isDocumentReadOnly }
                                                                >
                                                                    <Col md={ 11 }>
                                                                        <FormGroup>
                                                                            { index === 0 && <Label
                                                                                className="text-capitalize text-muted">{ label }</Label> }
                                                                            <FormField
                                                                                type="textarea"
                                                                                placeholder="Enter text"
                                                                                name={ `${ key }.value.${ index }` }
                                                                                disabled={ props.isDocumentReadOnly }
                                                                            />
                                                                            { values[key]?.value?.[index] === '' && (
                                                                                <>
                                                                                    <Input invalid
                                                                                           style={ { display: 'none' } }/>
                                                                                    <FormFeedback>Required
                                                                                        field</FormFeedback>
                                                                                </>
                                                                            ) }
                                                                        </FormGroup>
                                                                    </Col>
                                                                </InputRow>
                                                            )) }
                                                    </>
                                                ) }
                                            </FieldArray>
                                        );
                                    }
                                    return null;
                                }) }
                                <Row>
                                    { Object.keys(values).map((key: string) => {
                                        const type = values[key]?.type;
                                        const label = values[key]?.label;
                                        if (label && type !== 'array' && type === 'textarea') {
                                            return (
                                                <Col md={ 12 } key={ key }>
                                                    <FormGroup>
                                                        <Label className="text-muted">{ label }</Label>
                                                        <FormField
                                                            type="textarea"
                                                            placeholder={ label }
                                                            name={ `${ key }.value` }
                                                            style={ { width: 'auto', minWidth: '100%' } }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            );
                                        }
                                        return null;
                                    }) }
                                </Row>
                                <div>
                                    { !props.isDocumentReadOnly && (
                                        <Button
                                            className="btn btn-secondary rounded-0"
                                            type="button"
                                            onClick={ (): void => handleSubmit() }
                                            disabled={ props.isSaving || values['pipImpactingFactors']?.value?.[0] === '' }
                                        >
                                            CLICK TO UPDATE LETTER
                                        </Button>
                                    ) }
                                </div>
                            </Form>
                        </div>
                    ) }
                </CardBody>
            </Card>
        </>
    );
};

export default DocumentFields;
