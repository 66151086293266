import React, { ReactElement, useState } from 'react';
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, Row, Table } from 'reactstrap';
import FormField from '../../../../forms/fields/FormField.component';
import {
    ErrorMessage,
    FieldArray,
    FieldArrayRenderProps,
    Formik,
    FormikProps,
    FormikValues,
    useFormikContext,
} from 'formik';
import UploaderContainer from '../../../../components/Uploader/UploaderContainer';
import { Tooltip } from '../../../../components/tooltips/Tooltip.component';
import { AddIcon, CloseIcon, RemoveIcon } from '../../../../components/icon/Icon.component';
import InputRow from '../../../../components/inputRow/InputRow.component';
import { NewShortfallValidationSchema } from '../InitiatePIP/InitiatePIPForm';
import { v4 as uuidV4 } from 'uuid';
import { PipProgressMeeting } from '../RecordPIPProgressMeeting/RecordPIPProgressMeeting';
import { WorkflowComponentProps } from '../../../WorkflowContainer/workflow-utils';

interface RecordPIPDiscussionFormProps extends WorkflowComponentProps {
    processInstanceId: string;
    pipType: string;
}

const formatDateForInput = (dateStr: string | undefined | null): string => {
    if (!dateStr) return new Date().toISOString().split('T')[0];
    const parts = dateStr.split('/');
    return parts.length === 3 ? `${ parts[2] }-${ parts[1] }-${ parts[0] }` : new Date().toISOString().split('T')[0];
};

const RecordPIPDiscussionForm: React.FC<RecordPIPDiscussionFormProps> = props => {
    const { setFieldValue, values }: FormikProps<FormikValues> = useFormikContext();
    const [newShortFallModal, setNewShortfallModal] = useState(false);
    const { processInstanceId } = props;

    return (
        <>
            <Form>
                <Row>
                    <Col md={ 4 } sm={ 4 } lg={ 4 }>
                        <FormGroup>
                            <Label for="pipDiscussionDocuments" className="text-default text-capitalize">
                                Upload Summary of Discussion
                            </Label>
                            <UploaderContainer fieldName="pipDiscussionDocuments" path={ `cases/${ 1 }` }/>
                        </FormGroup>
                    </Col>
                    <Col md={ 1 } className="text-default py-5">
                        <FormGroup>OR</FormGroup>
                    </Col>
                    <Col md={ 6 } sm={ 6 } lg={ 6 }>
                        <FormGroup>
                            <Label for="pipDiscussionSummary" className="text-default text-capitalize">
                                Type the discussion summary*
                            </Label>
                            <FormField
                                type={ 'textarea' }
                                placeholder="Please add summary of discussion or upload document"
                                name="pipDiscussionSummary"
                            />
                            <span className="text-danger">
                <ErrorMessage className="text-danger" name={ 'pipDiscussionSummary' }/>
              </span>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div>
                            <h4 className="text-h4 font-weight-500">
                                { props.pipType === 'EXTENDED_PIP'
                                    ? 'Prepare final Extended PIP including Employee input'
                                    : 'Prepare final PIP including Employee input' }
                            </h4>
                            <h5 className="text-primary text-capitalize mt-4 font-weight-light">Case Overview:
                                Shortfalls</h5>
                            <hr style={ { border: '0.06em solid #adb5bd' } }/>
                        </div>
                    </Col>
                </Row>
                <Table bordered>
                    <thead>
                    <tr>
                        { [
                            'Performance Shortfall',
                            'Performance/Competency Goal',
                            'Quality Deliverable Standard',
                            'Deadline Timeline',
                            'Assistance Required From Employer',
                            'Action To Be Taken By Employee',
                        ].map((title, i) => {
                            const tooltipId = title.replace(/[^a-zA-Z0-9-_]/g, '');
                            return (
                                <th key={ i } className="text-default font-weight-bold text-center">
                  <span className="d-flex justify-content-between align-items-center">
                    <span>{ title }</span>
                    <Tooltip id={ tooltipId } title={ title } text={ `Information about ${ title }` }/>
                  </span>
                                </th>
                            );
                        }) }
                        <th style={ { width: '5%' } }/>
                    </tr>
                    </thead>
                    <tbody>
                    <FieldArray name="performanceShortfalls">
                        { (arrayHelpers: FieldArrayRenderProps) => (
                            <>
                                { values.performanceShortfalls &&
                                    values.performanceShortfalls.length &&
                                    values.performanceShortfalls.map((item: any, index: number) => (
                                        <tr key={ index }>
                                            { [
                                                'shortfallDescription',
                                                'performanceGoal',
                                                'qualityStandard',
                                                'deadline',
                                                'assistanceRequiredFromEmployer',
                                                'actionsToBeTakenByEmployee',
                                            ].map((field, idx) => (
                                                <td key={ idx } style={ { width: '12%' } }>
                                                    <input
                                                        type={ field === 'deadline' ? 'date' : 'text' }
                                                        value={ field === 'deadline' ? formatDateForInput(item[field]) : item[field] }
                                                        onChange={ (e) => setFieldValue(`performanceShortfalls.${ index }.${ field }`, e.target.value) }
                                                        style={ {
                                                            background: 'transparent',
                                                            border: 'none',
                                                            outline: 'none',
                                                            width: '100%',
                                                            textAlign: field === 'deadline' ? 'center' : 'left',
                                                            fontSize: 'inherit',
                                                            padding: '5px',
                                                            color: '#6a1b9a',
                                                            cursor: field === 'deadline' ? 'text' : 'text',
                                                        } }
                                                    />
                                                </td>
                                            )) }
                                            <td className="text-center">
                                                { index !== 0 && (
                                                    <span onClick={ () => arrayHelpers.remove(index) }
                                                          style={ { cursor: 'pointer' } }>
                            <RemoveIcon/>
                          </span>
                                                ) }
                                            </td>
                                        </tr>
                                    )) }
                            </>
                        ) }
                    </FieldArray>
                    </tbody>
                </Table>
                <Row className="pb-3">
                    <Col
                        md={ 3 }
                        className="d-flex justify-content-center"
                        style={ { cursor: 'pointer' } }
                        onClick={ (): void => setNewShortfallModal(true) }
                    >
            <span className="px-1">
              <AddIcon height={ '20' } width={ '20' }/>
            </span>
                        <span className="text-default px-1">Add a new Performance Shortfall</span>
                    </Col>
                </Row>
                <Row>
                    <Col md={ 4 } sm={ 4 } lg={ 4 }>
                        <FormGroup>
                            <Label for="pipStartDate" className="text-default text-capitalize">
                                PIP start date
                            </Label>
                            <FormField type={ 'date' } placeholder="Select Date" name="pipStartDate"/>
                            <span className="text-danger">
                <ErrorMessage className="text-danger" name={ 'pipStartDate' }/>
              </span>
                        </FormGroup>
                    </Col>
                    <Col md={ 4 } sm={ 4 } lg={ 4 }>
                        <FormGroup>
                            <Label for="pipEndDate" className="text-default text-capitalize">
                                PIP end date
                            </Label>
                            <FormField type={ 'date' } placeholder="Select Date" name="pipEndDate"/>
                            <span className="text-danger">
                <ErrorMessage className="text-danger" name={ 'pipEndDate' }/>
              </span>
                        </FormGroup>
                    </Col>
                </Row>
                <FieldArray name="pipProgressMeetings">
                    { (arrayHelpers: FieldArrayRenderProps) => (
                        <>
                            { !values.pipProgressMeetings &&
                                arrayHelpers.push({
                                    meetingId: uuidV4(),
                                    pipProgressMeetingDate: '',
                                    pipProgressMeetingTime: '',
                                    pipProgressMeetingLocation: '',
                                    pipProgressMeetingAttendees: '',
                                    sessionCompleted: false,
                                }) }
                            { values.pipProgressMeetings &&
                                values.pipProgressMeetings.length &&
                                values.pipProgressMeetings.map((item: PipProgressMeeting, index: number) => (
                                    <InputRow
                                        key={ index }
                                        index={ index }
                                        addCallback={ arrayHelpers.push }
                                        removeCallback={ arrayHelpers.remove }
                                        defaultValues={ {
                                            meetingId: uuidV4(),
                                            pipProgressMeetingDate: '',
                                            pipProgressMeetingTime: '',
                                            pipProgressMeetingLocation: '',
                                            pipProgressMeetingAttendees: '',
                                            sessionCompleted: false,
                                        } }
                                    >
                                        <>
                                            <Col md={ 4 }>
                                                <FormGroup>
                                                    <Label className="text-default">Scheduled Progress Meeting
                                                        Date*</Label>
                                                    <FormField
                                                        name={ `pipProgressMeetings.${ index }.pipProgressMeetingDate` }
                                                        placeholder={ 'Meeting Date' }
                                                        type={ 'date' }
                                                    />
                                                    <span className="text-danger">
                            <ErrorMessage name={ `pipProgressMeetings.${ index }.pipProgressMeetingDate` }/>
                          </span>
                                                </FormGroup>
                                            </Col>
                                            <Col md={ 4 }>
                                                <FormGroup>
                                                    <Label className="text-default">Scheduled Progress Meeting
                                                        Time*</Label>
                                                    <FormField
                                                        name={ `pipProgressMeetings.${ index }.pipProgressMeetingTime` }
                                                        placeholder={ 'Meeting Time' }
                                                        type={ 'time' }
                                                    />
                                                    <span className="text-danger">
                            <ErrorMessage name={ `pipProgressMeetings.${ index }.pipProgressMeetingTime` }/>
                          </span>
                                                </FormGroup>
                                            </Col>
                                        </>
                                    </InputRow>
                                )) }
                        </>
                    ) }
                </FieldArray>
            </Form>
            <Modal isOpen={ newShortFallModal } size={ 'lg' } centered>
                <ModalBody>
                    <div onClick={ (): void => setNewShortfallModal(false) } className="d-flex justify-content-between">
                        <div className="text-default h4">New Performance Shortfall</div>
                        <div>
                            <CloseIcon/>
                        </div>
                    </div>
                    <Formik
                        initialValues={ {
                            shortfallDescription: '',
                            date: '',
                            documents: null,
                            progressUpdates: [
                                {
                                    date: '',
                                    update: '',
                                },
                            ],
                            performanceGoal: '',
                            qualityStandard: '',
                            deadline: '',
                            assistanceRequiredFromEmployer: '',
                            actionsToBeTakenByEmployee: '',
                        } }
                        validationSchema={ NewShortfallValidationSchema }
                        onSubmit={ (currentValues: FormikValues): void => {
                            setFieldValue('performanceShortfalls', values.performanceShortfalls.concat(currentValues));
                            setNewShortfallModal(false);
                        } }
                    >
                        { ({ handleSubmit, values }: FormikProps<FormikValues>): ReactElement => (
                            <>
                                <Form>
                                    <Row>
                                        <Col md={ 12 }>
                                            <FormGroup>
                                                <Label for="shortfallDescription"
                                                       className="text-default text-capitalize">
                                                    Shortfall Description
                                                </Label>
                                                <FormField type={ 'textarea' } placeholder="Enter Description"
                                                           name="shortfallDescription"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'shortfallDescription' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="dateOrPeriod" className="text-default text-capitalize">
                                                    Date / Period
                                                </Label>
                                                <FormField type={ 'date' } placeholder="Select Date" name="date"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'date' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="dateOrPeriod" className="text-default text-capitalize">
                                                    Deadline
                                                </Label>
                                                <FormField type={ 'date' } placeholder="Select Date" name="deadline"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'deadline' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="performanceGoal" className="text-default text-capitalize">
                                                    Performance Goal
                                                </Label>
                                                <FormField type={ 'text' } placeholder="Enter Performance goal"
                                                           name="performanceGoal"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'performanceGoal' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="assistanceRequiredFromEmployer"
                                                       className="text-default text-capitalize">
                                                    Assistance Required from employer
                                                </Label>
                                                <FormField
                                                    type={ 'text' }
                                                    placeholder="Enter Description"
                                                    name="assistanceRequiredFromEmployer"
                                                />
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'assistanceRequiredFromEmployer' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="actionsToBeTakenByEmployee"
                                                       className="text-default text-capitalize">
                                                    Actions to be taken by the employee
                                                </Label>
                                                <FormField type={ 'text' } placeholder="Enter Description"
                                                           name="actionsToBeTakenByEmployee"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'actionsToBeTakenByEmployee' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label for="qualityStandard" className="text-default text-capitalize">
                                                    Quality/Standard
                                                </Label>
                                                <FormField type={ 'text' } placeholder="Enter Description"
                                                           name="qualityStandard"/>
                                                <span className="text-danger">
                          <ErrorMessage className="text-danger" name={ 'qualityStandard' }/>
                        </span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={ 6 }>
                                            <FormGroup>
                                                <Label className="text-default text-capitalize">Upload Documents</Label>
                                                <UploaderContainer fieldName={ 'documents' }
                                                                   path={ `cases/${ processInstanceId }` }
                                                                   isMulti={ true }/>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                                            onClick={ (): void => setNewShortfallModal(false) }
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="btn btn-bd-purple rounded-0 text-uppercase font-weight-normal"
                                            onClick={ (): void => handleSubmit() }
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </Form>
                            </>
                        ) }
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
};

export default RecordPIPDiscussionForm;
