import { createCognitoUser } from './cognito-utils';
import { CreateUserInput, UserRole } from '../API';
import { createUser } from '../graphql/mutations';
import { get, list, mutate } from './graphql-utils';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { listUsers } from '../graphql/queries';
import { User } from '../models';
import { EmailParamsV2, sendUserCreationMail } from './email-utils';
import { getUserBasic } from '../graphql-custom/custom-queries';
import { completeSpecificEmailTask } from './email-utils';
import { AxiosResponse } from 'axios';
import { convertToFlowableVariables } from './flowable/flowable-utils';

const getDBUserByEmail = async (emailAddress: string): Promise<User[]> => {
  const variables = { filter: { emailAddress: { eq: emailAddress.toLowerCase() } } };

  const fetchAllUsers = async (variables: any, accumulatedUsers: User[] = []): Promise<User[]> => {
    try {
      const res = await list(listUsers, variables);
      const users: User[] = res.data && (res.data as { [key: string]: any }).listUsers.items;
      const nextToken = res.data && (res.data as { [key: string]: any }).listUsers.nextToken;

      if (users) {
        accumulatedUsers = accumulatedUsers.concat(users);
      } else {
        throw new Error('Unexpected response from GraphQL');
      }

      if (nextToken) {
        return fetchAllUsers({ ...variables, nextToken }, accumulatedUsers);
      } else {
        return accumulatedUsers;
      }
    } catch (error) {
      throw error;
    }
  };

  return fetchAllUsers(variables);
};

const createUserOnDB = (user: CreateUserInput): Promise<any> => {
  return mutate(createUser, user);
};

const getRandomString = (): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const createNewUserWithoutSendingMail = (
  emailAddress: string,
  firstName: string,
  lastName: string,
  roles: UserRole[],
  organisationId?: string,
  employeeId?: string,
): Promise<{ user: User; temporaryPassword: string | null }> => {
  const temporaryPassword = getRandomString();
  return new Promise((resolve, reject) => {
    createCognitoUser(emailAddress.toLowerCase(), temporaryPassword)
      .then((response: CognitoIdentityServiceProvider.AdminCreateUserResponse) => {
        console.log('cognito create user response: ', response);
        if (response.User?.Username) {
          const dbUser: CreateUserInput = {
            cognitoSub: response.User.Username,
            firstName: firstName,
            lastName: lastName,
            active: true,
            roles: roles,
            organisationId: organisationId,
            userEmployeeId: employeeId,
            emailAddress: emailAddress.toLowerCase(),
          };
          createUserOnDB(dbUser)
            .then(res => {
              const dbUser: User = res.data?.createUser;
              if (dbUser) {
                resolve({ user: dbUser, temporaryPassword: temporaryPassword });
              } else {
                reject(new Error('Invalid response from database'));
              }
            })
            .catch(error => reject(error));
        } else {
          reject(new Error('Unexpected cognito response'));
        }
      })
      .catch(error => reject(error));
  });
};

export const createNewUser = (
  emailAddress: string,
  firstName: string,
  lastName: string,
  roles: UserRole[],
  organisationId?: string,
  employeeId?: string,
): Promise<{ user: User; temporaryPassword: string | null }> => {
  const temporaryPassword = getRandomString();
  return new Promise((resolve, reject) => {
    createCognitoUser(emailAddress.toLowerCase(), temporaryPassword)
      .then((response: CognitoIdentityServiceProvider.AdminCreateUserResponse) => {
        console.log('cognito create user response: ', response);
        if (response.User?.Username) {
          const dbUser: CreateUserInput = {
            cognitoSub: response.User.Username,
            firstName: firstName,
            lastName: lastName,
            active: true,
            roles: roles,
            organisationId: organisationId,
            userEmployeeId: employeeId,
            emailAddress: emailAddress.toLowerCase(),
          };
          createUserOnDB(dbUser)
            .then(res => {
              const dbUser: User = res.data?.createUser;
              if (dbUser) {
                sendUserCreationMail(emailAddress.toLowerCase(), firstName, temporaryPassword)
                  .then((res: any) => {
                    console.log('creation mail sent!', res);
                    resolve({ user: dbUser, temporaryPassword: temporaryPassword });
                  })
                  .catch(error => reject(error));
              } else {
                reject(new Error('Invalid response from database'));
              }
            })
            .catch(error => reject(error));
        } else {
          reject(new Error('Unexpected cognito response'));
        }
      })
      .catch(error => reject(error));
  });
};

export const resendUserInvite = async (emailAddress: string, firstName:string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const temporaryPassword = getRandomString();
    createCognitoUser(emailAddress, temporaryPassword, true)
      .then((response: CognitoIdentityServiceProvider.AdminCreateUserResponse) => {
        sendUserCreationMail(emailAddress.toLowerCase(), firstName, temporaryPassword)
        .then((res: any) => {
          console.log('creation mail sent!', res);
          resolve()
        })
        .catch(error => reject(error));
      })
      .catch(error => reject(error));
  });
};

export const getUserForCase = async (
  email: string,
  firstName: string,
  lastName: string,
  employeeId?: string,
  organisationId?: string,
): Promise<{ user: User; temporaryPassword: string | null }> => {
  const users = await getDBUserByEmail(email.toLowerCase().trim());
  if (users.length) {
    if (users.length > 1) {
      throw new Error('Multiple users found with matching email addresses');
    } else {
      return { user: users[0], temporaryPassword: null };
    }
  } else {
    console.log("new user being created")
    const newUser: { user: User; temporaryPassword: string | null } = await createNewUserWithoutSendingMail(
      email.toLowerCase().trim(),
      firstName,
      lastName,
      [UserRole.CASE_USER],
      organisationId,
      employeeId,
    );
    console.log('user created', newUser);
    return newUser;
  }
};

// get userID
// update workflow with ID
// send email, with temporary password if necessary

export const appointInvestigator = async (
  emailParams: EmailParamsV2,
  formValues: { [key: string]: any },
  emailAddress: string,
  firstName: string,
  lastName: string,
  employeeId?: string,
  organisationId?: string,
): Promise<AxiosResponse> => {
  if (!emailParams.masterProcessInstanceId) {
    throw new Error('Missing processInstanceId');
  }
  const res: { user: User; temporaryPassword: string | null } = await getUserForCase(
    emailAddress.toLowerCase().trim(),
    firstName,
    lastName,
    employeeId,
    organisationId,
  );
  console.log('user to be investigator: ', res);
  const user = res.user;
  if (res.temporaryPassword) {
    emailParams.newUserDetails = { username: res.user.emailAddress, temporaryPassword: res.temporaryPassword };
  }
  const vars = convertToFlowableVariables(formValues);
  vars.push({ name: 'emailParams', value: JSON.stringify(emailParams) });
  vars.push({ name: 'newInvestigatorUserId', value: user.id });
  if (emailParams.masterProcessInstanceId) {
    //  TODO: claim the task before completing it
    return completeSpecificEmailTask({
      processInstanceId: emailParams.processInstanceId,
      taskDefinitionKey: 'appoint-investigator-task',
      variables: vars,
    });
  } else {
    throw new Error('Missing processInstanceId');
  }
};

// export const appointInvestigatorv2 = (
//   masterProcessInstanceId: string,
//   formValues: FormikValues,
//   currentUser: UserDetails,
// ): Promise<void> => {
//   return new Promise((resolve, reject) => {
//     console.log('appointing...');
//     const organisationId = formValues.investigatorType === 'internal' ? currentUser.organisationId! : undefined;
//     if (!formValues.investigatorType || !currentUser.organisationId) {
//       reject(new Error('No organisation'));
//     }
//     getUserForCase(
//       formValues.investigatorEmailAddress.toLowerCase(),
//       formValues.investigatorFirstName,
//       formValues.investigatorLastName,
//       formValues.investigatorEmployeeId,
//       organisationId,
//     )
//       .then((res: { user: User; temporaryPassword: string | null }) => {
//         console.log('user to be investigator: ', res);
//         const confirmReceiptKey = moment()
//           .unix()
//           .toString();
//         const sendEmailRequest: EmailSendRequest = {
//           currentUserId: currentUser.id,
//           emailType: EmailType.APPOINT_INVESTIGATOR,
//           formValues: formValues,
//           masterProcessInstanceId: masterProcessInstanceId,
//           temporaryPassword: res.temporaryPassword ? res.temporaryPassword : undefined,
//           confirmReceiptKey: confirmReceiptKey,
//         };
//
//         const vars: FlowableVariable[] = [
//           { name: 'emailParams', value: JSON.stringify(sendEmailRequest) },
//           { name: 'lastInvestigatorUserId', value: res.user.id },
//         ];
//         if (masterProcessInstanceId) {
//           //  TODO: claim the task before completing it
//           completeSpecificEmailTask({
//             processInstanceId: processInstanceId,
//             taskDefinitionKey: 'appoint-investigator-task',
//             variables: vars,
//           })
//             .then(() => resolve())
//             .catch(error => reject(error));
//         } else {
//           reject(new Error('Missing processInstanceId'));
//         }
//       })
//       .catch(error => reject(error));
//   });
// };

export const appointChairperson = async (
  emailParams: EmailParamsV2,
  formValues: { [key: string]: any },
  emailAddress: string,
  firstName: string,
  lastName: string,
  employeeId?: string,
  organisationId?: string,
): Promise<AxiosResponse> => {
  if (!emailParams.masterProcessInstanceId) {
    throw new Error('Missing processInstanceId');
  }
  const res: { user: User; temporaryPassword: string | null } = await getUserForCase(
    emailAddress.toLowerCase(),
    firstName,
    lastName,
    employeeId,
    organisationId,
  );
  console.log('user to be chairperson: ', res);
  const user = res.user;
  if (res.temporaryPassword) {
    emailParams.newUserDetails = { username: res.user.emailAddress, temporaryPassword: res.temporaryPassword };
  }
  const vars = convertToFlowableVariables(formValues);
  vars.push({ name: 'emailParams', value: JSON.stringify(emailParams) });
  vars.push({ name: 'newChairpersonUserId', value: user.id });

  if (emailParams.masterProcessInstanceId) {
    return completeSpecificEmailTask({
      processInstanceId: emailParams.processInstanceId,
      taskDefinitionKey: 'appoint-chairperson-task',
      variables: vars,
    });
  } else {
    throw new Error('Missing processInstanceId');
  }
};

export const appointHcApprover = async (
  emailParams: EmailParamsV2,
  formValues: { [key: string]: any },
  emailAddress: string,
  firstName: string,
  lastName: string,
  employeeId?: string,
  organisationId?: string,
): Promise<AxiosResponse> => {
  if (!emailParams.masterProcessInstanceId) {
    throw new Error('Missing processInstanceId');
  }
  const res: { user: User; temporaryPassword: string | null } = await getUserForCase(
    emailAddress.toLowerCase(),
    firstName,
    lastName,
    employeeId,
    organisationId,
  );
  console.log('user to be hc approvver: ', res);
  const user = res.user;
  if (res.temporaryPassword) {
    emailParams.newUserDetails = { username: res.user.emailAddress, temporaryPassword: res.temporaryPassword };
  }
  const vars = convertToFlowableVariables(formValues);
  vars.push({ name: 'emailParams', value: JSON.stringify(emailParams) });
  vars.push({ name: 'newHCApproverID', value: user.id });

  if (emailParams.masterProcessInstanceId) {
    return completeSpecificEmailTask({
      processInstanceId: emailParams.processInstanceId,
      taskDefinitionKey: 'appoint-hc-approver-task',
      variables: vars,
    });
  } else {
    throw new Error('Missing processInstanceId');
  }
};

export const appointEmployerRepresentative = async (
  emailParams: EmailParamsV2,
  formValues: { [key: string]: any },
  emailAddress: string,
  firstName: string,
  lastName: string,
  employeeId?: string,
  organisationId?: string,
): Promise<AxiosResponse> => {
  if (!emailParams.masterProcessInstanceId) {
    throw new Error('Missing processInstanceId');
  }
  const res: { user: User; temporaryPassword: string | null } = await getUserForCase(
    emailAddress.toLowerCase(),
    firstName,
    lastName,
    employeeId,
    organisationId,
  );
  console.log('user to be employer rep: ', res);
  const user = res.user;
  if (res.temporaryPassword) {
    emailParams.newUserDetails = { username: res.user.emailAddress, temporaryPassword: res.temporaryPassword };
  }
  const vars = convertToFlowableVariables(formValues);
  vars.push({ name: 'emailParams', value: JSON.stringify(emailParams) });
  vars.push({ name: 'newEmployerRepresentativeUserId', value: user.id });

  return completeSpecificEmailTask({
    processInstanceId: emailParams.processInstanceId,
    taskDefinitionKey: 'appoint-employer-representative-task',
    variables: vars,
  });
};

export const getUserById = (id: string): Promise<User> => {
  return new Promise((resolve, reject) => {
    get(getUserBasic, id)
      .then(res => {
        const user: User | undefined = (res.data as any)?.getUser;
        if (user) {
          resolve(user);
        } else reject(new Error('No data on GraphQL response'));
      })
      .catch(error => reject(error));
  });
};

export function normalizeName(name: string) {
  if (!name) return ''
  let normalized = name.trim()
  normalized = normalized.replace(/\s+/g, ' ')
  normalized = normalized.replace(/([a-z])([A-Z])/g, '$1 $2')
  return normalized
}
